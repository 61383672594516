import {v4 as uuidv4} from "uuid";
import {Autocomplete, Chip} from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";

function TagSelector(
  {
    selectedTags,
    setSelectedTags,
    storedTags,
    label,
    placeholder,
    type,
  }) {

  const handleTagSelect = async (event, tags) => {
    setSelectedTags(tags.map(
      type === 'organization'
        ?
        tag => tag.name
          ? tag
          : {name: tag, id: uuidv4().toString('hex').replace(/-/g,'')}
        :
        tag => tag.tagName
          ? tag
          : {tagName: tag, id: uuidv4().toString('hex').replace(/-/g,'')}
    ))
  }

  return(
    <Autocomplete
      multiple
      freeSolo
      clearOnBlur
      id="fixed-tags-demo"
      value={selectedTags}
      onChange={handleTagSelect}
      options={storedTags}
      getOptionLabel={(option) => type === 'organization' ? option.name : option.tagName}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={type === 'organization' ? option.name : option.tagName}
            {...getTagProps({ index })}
          />
        ))
      }
      // style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          label={ label ? label : "Fixed tag" }
          placeholder={ placeholder ? placeholder : "Tags" }
        />
      )}
    />
  )
}

export default TagSelector;