import React, {useCallback, useEffect, useMemo, useState} from "react";
import {SimpleMdeReact} from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import MuiMarkdown from "mui-markdown";
import { storage } from "../.firebase/firebase_config";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";

const toolbar = [
  'bold',
  'italic',
  'strikethrough',
  'heading',
  '|',
  'code',
  'quote',
  'unordered-list',
  'ordered-list',
  '|',
  'link',
  'image',
  'table',
  'horizontal-rule',
  '|',
  'preview',
  'undo',
  'redo',
  '|',
  'guide',
]

export const MarkdownEditor = ({markdown, setMarkdown, contentId, editMode}) => {
  // const [edit, setEdit] = useState(false);
  const [md, setMd] = useState(markdown);

  const handleChange = useCallback((value) => {
    setMd(value);
    console.log(value)
  }, []);

  const imageUploadFunction = async (file) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, contentId + '/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error)
          reject(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
            setMd((md) => {return md + `![image](${downloadURL})`})
            resolve(downloadURL);
          });
        }
      );
    })
  }

  const options = useMemo(() => {
    return {
      "autofocus": true,
      "spellChecker": true,
      "toolbar": toolbar,
      "uploadImage": true,
      imageUploadFunction,
    };
  }, []);

  useEffect(() => {
    editMode ? setMd(markdown) : setMarkdown(md)
    // console.log('md: ', md)
    // console.log('markdown: ', markdown)
  }, [editMode])

  return (
    <>
      {
        editMode
          ? <SimpleMdeReact options={options} value={md} onChange={handleChange}/>
          : <MuiMarkdown>{ markdown }</MuiMarkdown>
      }
    </>
  )
};

export default MarkdownEditor;