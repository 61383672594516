import React, {useEffect, useState} from "react";
import {Button, Container, Grid, Pagination, Typography} from "@mui/material";
import {ProjectCard} from "./Cards";
// import {OpenProjectContents} from "./OpenProjectContents";
import PopupDialog from "./PopupDialog";
import axios from "axios";
import Box from "@mui/material/Box";
import {BackendURI} from "./BackendURI";


function OpenProjects({classes, account, query, projectId, setProjectId}) {
  const [page, setPage] = useState(1);
  const [pageMax, setPageMax] = useState(1);
  const [projects, setProjects] = useState([]);

  const handleChange = (event, value) => {
    setPage(value);
    query.length
      ?
      axios.get(BackendURI.uri + '/search?target=project&query=' + query + '&page=' + value, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        response.data.pageMax && setPageMax(response.data.pageMax)
        response.data.projects && setProjects(response.data.projects)
        console.log("SUCCESS", response.data.projects)
      }).catch(error => {
        console.log(error)
      })
      :
      axios.get(BackendURI.uri + '/project?page=' + value, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        setPageMax(response.data.pageMax)
        setProjects(response.data.projects)
        console.log("SUCCESS (page)", response.data.projects)
      }).catch(error => {
        console.log(error)
      })
  };
  console.log("search query: " + query)

  useEffect(() => {
    setProjectId(null)

    const timer = setTimeout(() => {
      query.length
        ?
        axios.get(BackendURI.uri + '/search?target=project&query=' + query, {
          headers: {
            Authorization: localStorage.getItem('idToken')
          }
        }).then(response => {
          response.data.pageMax && setPageMax(response.data.pageMax)
          response.data.projects && setProjects(response.data.projects)
          setPage(1)
          console.log("SUCCESS", response.data.projects)
        }).catch(error => {
          console.log(error)
        })
        :
        axios.get(BackendURI.uri + '/project', {
          headers: {
            Authorization: localStorage.getItem('idToken')
          }
        }).then(response => {
          setPageMax(response.data.pageMax)
          setProjects(response.data.projects)
          console.log("SUCCESS", response.data.projects)
        }).catch(error => {
          console.log(error)
        })
    }, query.length ? 800 : 0)

    return () => clearTimeout(timer)
  },[query])

  return(
    <>
      <div className={classes.container}>
        <Container maxWidth={'lg'}>
          <Typography variant={'h1'} align={'left'} color={'primary'} gutterBottom>
            Open Projects
          </Typography>
          <Typography variant={'subtitle1'} align={'left'} color={'textSecondary'} paragraph>
            Grow your data science skills by competing in our exciting competitions.
            Find help in the documentation or learn about Community Competitions.
          </Typography>
          <div className={classes.buttons}>
            <Grid container spacing={2} justifyContent={'left'}>
              <Grid item>
                <PopupDialog
                  buttonVariant={"contained"}
                  buttonColor={'primary'}
                  buttonTitle={"Host a project"}
                  dialogTitleText={"Create A New Project"}
                  dialogContentText={"test test"}
                  account={account}
                  type={'project'}
                />
              </Grid>
              <Grid item>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() => {window.location.pathname = '/usr'}}
                >
                  My Work
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <div className={classes.container}>
        <Container className={classes.cardGrid} maxWidth={'lg'}>
          <Grid container spacing={2} justifyContent={'right'}>
            <Grid item>
              <Box sx={{m: 2}}>
                <Pagination count={pageMax} page={page} onChange={handleChange} />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            {
              projects.length > 0 && projects.map((project, key) => (
                <Grid item key={key} xs={12} sm={6} md={4} lg={3}>
                  { project.teamMembers.length > 0 && ProjectCard(project) }
                </Grid>
              ))
            }
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default OpenProjects;
