import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import {Button, Chip, Container, FormControlLabel, Grid, InputAdornment, Typography} from "@mui/material";
import { useParams } from 'react-router-dom';
import axios from "axios";
import Comments from "./Comments";
import MarkdownEditor from "./MarkDownEditor";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CustomAvatar from "./Avatar";
import { DateTime } from "luxon";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import TagSelector from "./TagSelector";
import TextField from "@mui/material/TextField";
import PopupDialog from "./PopupDialog";
import {ReportCard} from "./Cards";
import {ImageOutlined} from "@mui/icons-material";
// import ReactMarkdown from "react-markdown";
// import SimpleMDE from 'react-simplemde-editor';
// import 'easymde/dist/easymde.min.css';
import handleFileUpload from "./FileUploadHandler";
import {BackendURI} from "./BackendURI";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';


function UserBox({user, orgs}){
  return(
    <Box sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }}>
      <Box>
        <IconButton
          color="inherit"
          onClick={() => console.log("hi " + user.name)}
        >
          { CustomAvatar(user, 48) }
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Box sx={{ px: 2 }}>
          <Typography variant="body1" component="span">
            { user.name }
          </Typography>
        </Box>
        <Box sx={{ px: 2 }}>
          <Typography variant="body1" component="span">
            {
              user.organization
                ?
                orgs && (
                  user.organization.split(', ').map((oid, key) => (
                    orgs.filter(function(orgs){
                      return (orgs.id === oid);
                    })[0].name
                  )).reduce((pre, cur) => (
                    pre + ', ' + cur
                  ))
                )
                :
                ''
            }
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}


function ContentHeader(
  { params,
    title,
    setTitle,
    subtitle,
    setSubtitle,
    content,
    tags,
    setTags,
    kpis,
    setKpis,
    user,
    setUser,
    voteCount,
    setVoteCount,
    editMode,
    setEditMode,
    abstract,
    setAbstract,
    account,
  }) {
  const classes = useStyles()
  const [storedTags, setStoredTags] = useState([])
  const [storedKpis, setStoredKpis] = useState([]);
  const [storedOrgs, setStoredOrgs] = useState(null);
  const [storedUsers, setStoredUsers] = useState([])
  const [upVoted, setUpVoted] = useState(false);


  useEffect(() => {
    tags && (
      axios.get(BackendURI.uri + '/tag', {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        console.log('SUCCESS - tags', response)
        setStoredTags(response.data)
      }).catch((error) => {
        console.log(error)
      })
    )
    kpis && (
      axios.get(BackendURI.uri + '/kpi', {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        console.log('SUCCESS - kpis', response)
        setStoredKpis(response.data)
      }).catch((error) => {
        console.log(error)
      })
    )
    axios.get(BackendURI.uri + '/user?type=organization', {
      headers: {
        Authorization: localStorage.getItem('idToken')
      }
    }).then(response => {
      console.log('SUCCESS', response)
      setStoredOrgs(response.data)
    }).catch((error) => {
      console.log(error)
    })
    axios.get(BackendURI.uri + '/user?organization=' + account.organization[0].id, {
      headers: {
        Authorization: localStorage.getItem('idToken')
      }
    }).then(response => {
      console.log('SUCCESS', response)
      setStoredUsers(response.data)
    }).catch((error) => {
      console.log(error)
    })
  },[tags, kpis])

  const handleToggleEditMode = () => {
    setEditMode((editMode) => !editMode);
  };

  const handleUpVote = () => {
    !upVoted && setVoteCount(voteCount + 1)
    setUpVoted(true)
  }

  return (
    <>
      <Box sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
      }}>
        <Box>
          <Typography variant={'body2'}>Id: {params.id}</Typography>
        </Box>
        <Box sx={{ marginLeft: "auto" }}>
          <Button
            className={classes.upVoteButton}
            variant="outlined"
            startIcon={ <ArrowDropUpIcon /> }
            onClick={handleUpVote}
            color="inherit"
          >
            { voteCount }
          </Button>
        </Box>
        <Box sx={{mx: 2}}>
          <IconButton
            color="inherit"
            aria-label="edit"
            onClick={handleToggleEditMode}
          >
            { editMode ? <SaveIcon /> : <EditIcon /> }
          </IconButton>
        </Box>
      </Box>
      {
        editMode
          ?
          <TextField
            margin="dense"
            id="title"
            label="Title"
            value={title}
            fullWidth
            variant="outlined"
            onChange={e => { setTitle(e.target.value) }}
          />
          :
          <Typography variant={'h1'} align={'left'} color={'primary'} >
            { title }
          </Typography>
      }
      {
        editMode
          ?
          <TextField
            margin="dense"
            id="subtitle"
            label="Subtitle"
            value={subtitle}
            fullWidth
            variant="outlined"
            onChange={e => { setSubtitle(e.target.value) }}
            />
          :
          <Typography variant={'subtitle1'} align={'left'} color={'inherit'} >
            { subtitle }
          </Typography>
      }

      <Box sx={{
        display: "flex",
        flexDirection: "row",
        p: 1,
        m: 1,
        alignItems: "top",
      }}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignItems: "left",
        }}>
          {
            editMode
              ? (
                setUser
                  ?
                  <TagSelector
                    selectedTags={user}
                    setSelectedTags={setUser}
                    storedTags={storedUsers}
                    label={"Users"}
                    placeholder={"Users"}
                    type={'organization'}
                  />
                  : (
                    user instanceof Array
                      ? user.map((member, key) => (
                          <UserBox user={member} orgs={storedOrgs} key={key}/>
                        ))
                      : <UserBox user={user} orgs={storedOrgs} />
                  )
              )
              : (
                user instanceof Array
                  ? user.map((member, key) => (
                      <UserBox user={member} orgs={storedOrgs} key={key}/>
                    ))
                  : <UserBox user={user} orgs={storedOrgs} />
              )
          }
        </Box>

        <Box sx={{
          marginLeft: "auto",
          px: 2,
          my: 1,
        }}>
          <Typography variant="body1" component="span">
            Created: { DateTime.fromSQL(content.created).toRelative() } <br />
            Updated: { DateTime.fromSQL(content.updated).toRelative() }
          </Typography>
        </Box>
      </Box>

      <Box>
        {
          ( editMode && kpis )
            ?
            <TagSelector
              selectedTags={kpis}
              setSelectedTags={setKpis}
              storedTags={storedKpis}
              label={"KPI"}
              placeholder={"KPI"}
            />
            : (
              kpis instanceof Array && kpis.map((kpi, key) => (
                kpi instanceof Object && <Chip label={kpi.tagName} key={key} sx={{p: 1, ml: 2, my: 1}}/>
              ))
            )
        }
        {
          editMode
            ?
            <TagSelector
              selectedTags={tags}
              setSelectedTags={setTags}
              storedTags={storedTags}
              label={"Tags"}
              placeholder={'Tags'}
            />
            : (
              tags instanceof Array && tags.map((tag, key) => (
                tag instanceof Object && <Chip label={tag.tagName} key={key} sx={{p: 1, ml: 2, my: 1}}/>
              ))
            )
        }
        {
          content.estTags instanceof Array && content.estTags.map((tag, key) => (
            tag instanceof Object && <Chip label={tag.tagName} key={key} color={'info'} sx={{p: 1, ml: 2, my: 1}}/>
          ))
        }
      </Box>
      {
        abstract && (
          editMode
            ?
            <TextField
              margin="dense"
              id="abstract"
              label="abstract"
              value={abstract}
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              onChange={e => { setAbstract(e.target.value) }}
            />
            :
            <>
              <Typography variant={'h2'} align={'left'} color={'inherit'}>
                Abstract
              </Typography>
              <Typography variant={'body1'} align={'left'} color={'inherit'} >
                { abstract }
              </Typography>
            </>
        )
      }

    </>
  )
}


export function DatasetDetail({classes, account, projectId, setProjectId}) {
  const params = useParams();
  const [loading, setLoading] = useState(true)
  const [getDataset, setGetDataset] = useState({});
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [dataOwner, setDataOwner] = useState({});
  const [markdown, setMarkdown] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [voteCount, setVoteCount] = useState(0);
  const [tags, setTags] = useState([]);


  useEffect(() => {
    setProjectId(null)

    const handleUpdateDataset = async () => {
      const updatedAt = DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false })
      const updates = {
        id: params.id,
        title: title,
        subtitle: subtitle,
        updated: updatedAt,
        description: markdown,
        tag: tags,
        voteCount: voteCount,
      }
      axios.put(BackendURI.uri + '/data', updates, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then((response) => {
        console.log(response.data)
        response.data.dataset && setGetDataset(response.data.dataset)
      }).catch((error) => {
        console.log(error)
      })
    }
    !editMode && !loading && handleUpdateDataset()

    loading && (
      axios.get(BackendURI.uri + '/data/' + params.id, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        console.log("SUCCESS", response)
        setGetDataset(response.data)
        setTitle(response.data.title)
        setSubtitle(response.data.subtitle)
        setDataOwner(response.data.dataOwner)
        setMarkdown(response.data.description)
        setTags(response.data.tag)
        setVoteCount(response.data.voteCount - 0)
        setLoading(false)
      }).catch(error => {
        console.log(error)
      })
    )
  }, [loading, editMode, markdown, voteCount])

  return(
    <div className={classes.container}>
      <Container maxWidth={'lg'}>
        {
          loading
          ? <Typography>Loading...</Typography>
          : <ContentHeader
              params={params}
              title={title}
              setTitle={setTitle}
              subtitle={subtitle}
              setSubtitle={setSubtitle}
              content={getDataset}
              tags={tags}
              setTags={setTags}
              user={dataOwner}
              voteCount={voteCount}
              setVoteCount={setVoteCount}
              editMode={editMode}
              setEditMode={setEditMode}
              account={account}
            />
        }

        <hr />

        <MarkdownEditor
          markdown={markdown}
          setMarkdown={setMarkdown}
          contentId={params.id}
          editMode={editMode}
        />
        <Comments account={account} threadId={params.id}/>
      </Container>
    </div>
  )
}

export function ProjectDetail({classes, account, projectId, setProjectId}) {
  const params = useParams();
  const [loading, setLoading] = useState(true)
  const [getProject, setGetProject] = useState({});
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [teamMembers, setTeamMembers] = useState({});
  const [markdown, setMarkdown] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [voteCount, setVoteCount] = useState(0);
  const [tags, setTags] = useState([]);
  const [kpis, setKpis] = useState([]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    setProjectId(params.id)
    const handleUpdateProject = async () => {
      const updatedAt = DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false })
      const updates = {
        id: params.id,
        title: title,
        subtitle: subtitle,
        updated: updatedAt,
        teamMembers: teamMembers,
        description: markdown,
        kpi: kpis,
        tag: tags,
        voteCount: voteCount,
        report: reports,
      }
      axios.put(BackendURI.uri + '/project', updates, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then((response) => {
        console.log(response)
        response.data.project && setGetProject(response.data.project)
      }).catch((error) => {
        console.log(error)
      })
    }
    !editMode && !loading && handleUpdateProject()

    loading && (
      axios.get(BackendURI.uri + '/project/' + params.id, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        console.log("SUCCESS", response)
        setGetProject(response.data)
        setTitle(response.data.title)
        setSubtitle(response.data.subtitle)
        setTeamMembers(response.data.teamMembers)
        setMarkdown(response.data.description)
        setTags(response.data.tag)
        setKpis(response.data.kpi)
        setVoteCount(response.data.voteCount ? response.data.voteCount : 0)
        setLoading(false)
      }).catch(error => {
        console.log(error)
      })
    )
    loading && (
      axios.get(BackendURI.uri + '/report?project=' + params.id, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        setReports(response.data.reports)
        console.log("Retrieved projects", response.data.reports)
      }).catch(error => {
        console.log(error)
      })
    )

  }, [loading, editMode, markdown, voteCount, params.id])

  return(
    <div className={classes.container}>
      <Container maxWidth={'lg'}>
        {
          loading
          ? <Typography>Loading...</Typography>
          : <ContentHeader
              params={params}
              title={title}
              setTitle={setTitle}
              subtitle={subtitle}
              setSubtitle={setSubtitle}
              content={getProject}
              tags={tags}
              setTags={setTags}
              kpis={kpis}
              setKpis={setKpis}
              user={teamMembers}
              setUser={setTeamMembers}
              voteCount={voteCount}
              setVoteCount={setVoteCount}
              editMode={editMode}
              setEditMode={setEditMode}
              account={account}
            />
        }

        <hr />

        <MarkdownEditor
          markdown={markdown}
          setMarkdown={setMarkdown}
          contentId={params.id}
          editMode={editMode}
        />

        <hr />

        <Typography variant={'h2'}>
          Graphlet
        </Typography>
        <div className={classes.buttons}>
          <Grid container spacing={2} justifyContent={'left'}>
            <Grid item>
              {
                loading
                  ? <Typography>Loading...</Typography>
                  : <PopupDialog
                      buttonVariant={"contained"}
                      buttonColor={'primary'}
                      buttonTitle={"New Graphlet"}
                      dialogTitleText={"Create A New Graphlet"}
                      dialogContentText={"test test"}
                      account={account}
                      type={'report'}
                      projectContent={getProject}
                    />
              }
            </Grid>
          </Grid>
        </div>

        <Container className={classes.cardGrid} maxWidth={'lg'}>
          <Grid container spacing={4}>
            {
              reports.length > 0 && reports.map((report, key) => (
                <Grid item key={key} xs={12} sm={6} md={4} lg={3}>
                   { ReportCard(report, classes) }
                </Grid>
              ))
            }
          </Grid>
        </Container>

        <Comments account={account} threadId={params.id}/>
      </Container>
    </div>
  )
}

export function ReportDetail({classes, account, projectId, setProjectId}) {
  const params = useParams();
  const [loading, setLoading] = useState(true)
  const [getReport, setGetReport] = useState({});
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [abstract, setAbstract] = useState('');
  const [keyImage, setKeyImage] = useState(null);
  const [keyImageFile, setKeyImageFile] = useState(null);
  const [caption, setCaption] = useState('');
  const [author, setAuthor] = useState({});
  const [markdown, setMarkdown] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [voteCount, setVoteCount] = useState(0);
  const [tags, setTags] = useState([]);

  const handleFileSelect = (e) => {
    if (e.target.files[0]) {
      setKeyImageFile(e.target.files[0]);
      setKeyImage(e.target.files[0].name);
    }
  }

  useEffect(() => {
    const handleUpdateReport = async () => {
      const updatedAt = DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false })
      const downloadURL = keyImageFile ? await handleFileUpload(params.id, keyImageFile) : keyImage
      downloadURL && setKeyImage(downloadURL);
      const updates = {
        id: params.id,
        title: title,
        subtitle: subtitle,
        author: author,
        abstract: abstract,
        updated: updatedAt,
        keyImage: downloadURL,
        caption: caption,
        description: markdown,
        tag: tags,
        voteCount: voteCount,
      }
      console.log(updates)
      axios.put(BackendURI.uri + '/report', updates, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then((response) => {
        console.log(response)
        response.data.report && setGetReport(response.data.report)
      }).catch((error) => {
        console.log(error)
      })
    }

    loading && (
      axios.get(BackendURI.uri + '/report/' + params.id, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        console.log("SUCCESS", response)
        setGetReport(response.data)
        setTitle(response.data.title)
        setSubtitle(response.data.subtitle)
        setAbstract(response.data.abstract)
        setAuthor(response.data.author)
        setKeyImage(response.data.keyImage)
        setCaption(response.data.caption)
        setMarkdown(response.data.description)
        setTags(response.data.tag)
        setVoteCount(response.data.voteCount)
        setProjectId(response.data.projectId)
        setLoading(false)
      }).catch(error => {
        console.log(error)
      })
    )
    // !editMode && handleUpdateReport()
    !editMode && !loading && handleUpdateReport()
  }, [loading, editMode, keyImageFile, markdown, voteCount])

  return(
    <div className={classes.container}>
      <Container maxWidth={'lg'}>
        {
          loading
          ? <Typography>Loading...</Typography>
          : <ContentHeader
              params={params}
              title={title}
              setTitle={setTitle}
              subtitle={subtitle}
              setSubtitle={setSubtitle}
              content={getReport}
              tags={tags}
              setTags={setTags}
              user={author}
              setUser={setAuthor}
              voteCount={voteCount}
              setVoteCount={setVoteCount}
              editMode={editMode}
              setEditMode={setEditMode}
              abstract={abstract}
              setAbstract={setAbstract}
              account={account}
            />
        }

        <hr />

        {
          editMode
            ?
            <>
              <TextField
                margin="dense"
                id="coverImage"
                label="Key image"
                placeholder={keyImage ? keyImage : "File path to image file"}
                value={keyImage ? keyImage : ""}
                type="text"
                fullWidth
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton component={"label"}>
                        <input hidden accept="image/*" type="file" onChange={handleFileSelect}/>
                        <ImageOutlined/>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
              </TextField>
            </>
            :
            <div className={classes.imageContainer}>
              <img className={classes.keyImage} alt="key image" src={keyImage} />
            </div>
        }

        {
          caption && (
            editMode
              ?
              <TextField
                margin="dense"
                id="caption"
                label="caption"
                value={caption}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                onChange={e => { setCaption(e.target.value) }}
              />
              :
              <>
                <Typography
                  component={'span'}
                  variant={'body1'}
                  align={'left'}
                  color={'inherit'}
                >
                  <Box fontWeight='fontWeightBold' display='inline'>Fig: </Box>{ caption }
                </Typography>
              </>
          )
        }

        <Typography variant={'h2'} align={'left'} color={'inherit'}>Description</Typography>

        <MarkdownEditor
          markdown={markdown}
          setMarkdown={setMarkdown}
          contentId={params.id}
          editMode={editMode}
        />

        <hr />
        <Comments account={account} threadId={params.id}/>
      </Container>
    </div>
  )
}
