import React from 'react';
import {styled, alpha, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { SidebarMainContents, SidebarSubContents } from "./SidebarContents";
import MILogo from './MI-logo.svg';
import { Container, Menu, MenuItem } from "@mui/material";
import CustomAvatar from "./Avatar";
import { logout } from "./Login"
import { useNavigate } from "react-router-dom";
import Chat from "./Chat";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'initial',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer,
  background: theme.palette.background.paper,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: "1px solid lightgrey",
  borderRadius: 20,
  backgroundColor: alpha(theme.palette.background.paper, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.25),
  },
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'primary',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100',
    },
  },
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer({ open, setOpen, query, setQuery, user, setUser, setUserId, projectId }) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAccountMenu = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleAccountMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    setAnchorEl(null);
    navigate("/profile")
  }

  async function handleLogout() {
    setAnchorEl(null);
    try {
     await logout();
     setUser(null)
     setUserId(null)
    } catch (error) {
     console.log(error)
    }
  }

  const handleToggleDrawer = () => {
    setOpen((o) => !o);
  };
  
  const handleSearch = (e) => {
    e.preventDefault();

    if (query) {
      console.log("search query: " + query)
    }
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <AppBar position="fixed" open={open} elevation={0}>
        <Toolbar>
          {/*<Box sx={{width: open ? 0 : closedMixin(theme).width}}/>*/}
          <Box sx={{ width: closedMixin(theme).width }}/>
          <Box sx={{ flexGrow: 1, marginLeft: "auto" }}>
          <Container maxWidth={'lg'}>
            <form onSubmit={handleSearch}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon color="action"/>
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </Search>
            </form>
          </Container>
          </Box>

          <IconButton
            id={"account-menu-button"}
            aria-controls={openAccountMenu ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openAccountMenu ? 'true' : undefined}
            onClick={handleAccountMenuOpen}
            // onClick={() => console.log("hi " + UserAccount.name)}
            color="inherit"
            sx={{ marginLeft: "auto" }}
          >
            { CustomAvatar(user) }
          </IconButton>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={openAccountMenu}
            onClose={handleAccountMenuClose}
            MenuListProps={{
              'aria-labelledby': 'account-menu-button',
            }}
          >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: 'space-between',
            alignItems: 'initial',
          }}>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: 'flex-start',
              alignItems: "center",
              py: 1,
            }}>
              <Box>
                <IconButton
                  onClick={handleToggleDrawer}
                  sx={{
                    mr: open ? 1 : 'auto',
                    borderRadius: 0
                    // justifyContent: 'center',
                  }}
                >
                  <MenuIcon sx={{height: 32, width: 32}}/>
                </IconButton>
              </Box>
              <Box sx={{ opacity: open ? 1 : 0, }}>
                <img src={ MILogo } alt="MI Logo" />
              </Box>
            </Box>
          </Box>
        </DrawerHeader>

        <Divider />

        <List>
          { SidebarMainContents.map((value, key) => (
            <ListItem key={key} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => {window.location.pathname = value.link}}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {value.icon}
                </ListItemIcon>
                <ListItemText primary={value.title} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {SidebarSubContents.map((value, key) => (
            <ListItem key={key} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => {window.location.pathname = value.link}}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {value.icon}
                </ListItemIcon>
                <ListItemText primary={value.title} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      </Box>
      <MuiDrawer
        variant="permanent"
        open
        anchor="right"
        sx ={{
          zIndex: 0,
          "& .MuiDrawer-paper": { borderWidth: 0 },
        }}
        PaperProps={{
            sx: { width: "80px" },
        }}
      >
        <Box sx={{width: "40px"}}>
          {/*<List></List>*/}
          <List sx={{ position: "absolute", bottom: "0", marginTop: "auto"}}>
            <ListItem>
              { projectId ? <Chat projectId={projectId}/> : <></>}
            </ListItem>
          </List>
        </Box>
      </MuiDrawer>
    </Box>
  );
}
