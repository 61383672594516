import React, {useEffect, useState} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut } from "firebase/auth"
import { auth } from "../.firebase/firebase_config";
import {useNavigate} from "react-router-dom";
import GoogleButton from "react-google-button";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Link,
  TextField,
  Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import useStyles from "../styles";
import MILogo from "./MI-logo.svg";
import axios from "axios";
import {DateTime} from "luxon";
import {BackendURI} from "./BackendURI";

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = {
      id: result.user.uid,
      name: result.user.displayName,
      email: result.user.email,
      tel: result.user.phoneNumber,
      avatarImage: result.user.photoURL,
      lastLogin: DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false }),
      type: 'individual'
    }
    console.log('login user: ', user)
    localStorage.setItem("account", JSON.stringify(user));
    return user;
  } catch (error) {
    console.log(error);
    alert(error.message);
  }
}

export const logInWithEmailAndPassword = async (account) => {
  try {
    const result = await signInWithEmailAndPassword(auth, account.email, account.password);
    const user = {
      id: result.user.uid,
      name: account.name,
      email: result.user.email,
      tel: result.user.phoneNumber,
      avatarImage: result.user.photoURL,
      lastLogin: DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false }),
      type: 'individual'
    }
    console.log('login user: ', user)
    localStorage.setItem("account", JSON.stringify(user));
    return user;
  } catch (error) {
    console.log(error);
    alert(error.message);
  }
}

export const registerWithEmailAndPassword = async (account) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, account.email, account.password);
    const user = {
      id: result.user.uid,
      name: account.name,
      email: result.user.email,
      tel: result.user.phoneNumber,
      avatarImage: result.user.photoURL,
      joined: DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false }),
      lastLogin: DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false }),
      type: 'individual'
    }
    console.log('login user: ', user)
    localStorage.setItem("account", JSON.stringify(user));
    return user;
  } catch (error) {
    console.log(error);
    alert(error.message);
  }
};

export const logout = async () => {
  await signOut(auth);
  console.log("logout")
  localStorage.clear()
};

const LoginCard = styled(Card)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  elevation: "0",
  border: "1px solid lightgrey",
  boxShadow: "none",
  marginTop: "128px",
  alignItems: "center",
  justifyContent: "center",
}));

const LoginCardContent = styled(CardContent)(() => ({
  // flexGrow: 1,
  marginLeft: "16px",
  marginRight: "16px",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}))

const LoginCardActions = styled(CardActions)(() => ({
  justifyContent: "center",
  marginLeft: "16px",
  marginRight: "16px",
}))

export function Login({setUserId, idToken, setIdToken}){
  const [register, setRegister] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [account, setAccount] = useState({ name: "", email: "", password: "" });
  const [loginUser, setLoginUser] = useState(null);
  const [authenticated, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const classes = useStyles();

  async function handleLogInWithEmail(){
    // event.preventDefault();
    const user = register
      ? await registerWithEmailAndPassword(account)
      : await logInWithEmailAndPassword(account)

    setLoginUser(user)
  }

  async function handleResetPassword(){
    await sendPasswordResetEmail(auth, account.email)
    setResetPassword(false)
    setRegister(false)
  }

  async function handleSubmit(event){
    event.preventDefault()
    resetPassword
      ? await handleResetPassword()
      : await handleLogInWithEmail()
  }

  async function handleLogInWithGoogle(){
    const user = await signInWithGoogle();
    setLoginUser(user)
  }

  function handleChange(event){
    const { name, value } = event.target;
    setAccount((prevValue) => {
      return {
        ...prevValue,
        [name]: value
      };
    });
  }

  useEffect(() => {
    const getIdToken = async () => {
      auth.currentUser.getIdToken(true).then((response) => {
        setIdToken(response)
        localStorage.setItem("idToken", response);
        // console.log('token', response)
        return response
      }).catch((error) => {
        console.log(error)
      })
    }

    const storeNewUserInfo = async () => {
      axios.post(BackendURI.uri + '/user', loginUser, {
        headers: {
          Authorization: idToken
        }
      }).then((response) => {
        console.log(response)
        setUserId(loginUser.id)
      }).catch((error) => {
        console.log(error)
      })
    }

    const updateUserInfo = async () => {
      axios.put(BackendURI.uri + '/user', loginUser, {
        headers: {
          Authorization: idToken
        }
      }).then((response) => {
        console.log(response)
        setUserId(loginUser.id)
      }).catch((error) => {
        console.log(error)
      })
    }

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (authenticated && loginUser) {
      getIdToken().then()
    }
    if (idToken) {
      console.log('token', idToken)
      try {
        axios.get(BackendURI.uri + '/user/' + loginUser.id, {
          headers: {
            Authorization: idToken
          }
        }).then()
        updateUserInfo().then()
      } catch (error) {
        storeNewUserInfo().then()
      }
      navigate("/home");
    }
  }, [authenticated, loading, loginUser, idToken]);

  // console.log('input: ', account)
  // console.log('token', auth.currentUser.getIdToken())

  return (
    <>
      <div className={classes.container}>
        <Container maxWidth={'xs'}>
          <LoginCard>
            <LoginCardContent>
              <Box sx={{m: 3}}>
                <img src={ MILogo } alt="MI Logo" style={{height: "auto", width: "80%"}} />
              </Box>
              <Typography
                variant="h4"
                color={'textSecondary'}
                align={"center"}
                sx={{m: 2}}
              >
                {
                  resetPassword
                    ? "Reset password"
                    : (
                      register ? "Sign up" : "Sign in"
                    )
                }
              </Typography>
              <form onSubmit={handleSubmit}>
                {
                  register && (
                    <TextField
                      onChange={handleChange}
                      name="name"
                      type="text"
                      placeholder="Full Name"
                      value={account.name}
                      fullWidth
                      margin="dense"
                    />
                  )
                }
                <TextField
                  onChange={handleChange}
                  name="email"
                  type="email"
                  placeholder="Email address"
                  value={account.email}
                  fullWidth
                  margin="dense"
                />
                {
                  !resetPassword && (
                    <TextField
                      onChange={handleChange}
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={account.password}
                      fullWidth
                      margin="dense"
                    />
                  )
                }
                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                  <Box sx={{ mx: 1, marginRight: "auto" }}>
                    {
                      resetPassword
                        ?
                        <Link
                          component={"button"}
                          type={"button"}
                          variant={"caption"}
                          onClick={() => {
                            setResetPassword(false)
                            setRegister(false)
                          }}
                        >
                          Already have an account?
                        </Link>
                        :
                        <Link
                          component={"button"}
                          type={"button"}
                          variant={"caption"}
                          onClick={() => {
                            setResetPassword(true)
                          }}
                        >
                          Forgot password?
                        </Link>
                    }
                  </Box>
                </Box>
                <LoginCardActions>
                  <Button
                    className="loginBtn"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {
                      resetPassword
                        ? "Reset password"
                        : (
                          register ? "Sign up" : "Sign in"
                        )
                    }
                  </Button>
                </LoginCardActions>
              </form>
              {
                !resetPassword && (
                  <>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align={"center"}
                    >
                    or
                    </Typography>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: 1,
                      marginBottom: 4,
                    }}>
                      <Box>
                        <GoogleButton
                          type="light"
                          onClick={handleLogInWithGoogle}
                          label={register ? "Sing up with Google" : "Sign in with Google"}
                        />
                      </Box>
                    </Box>
                    {
                      register
                        ?
                        <>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            align={"center"}
                          >
                            Already have an account?
                          </Typography>
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                              setResetPassword(false)
                              setRegister(false)
                            }}
                          >
                            Sign in
                          </Link>
                        </>
                        :
                        <>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            align={"center"}
                          >
                            Don't have an account?
                          </Typography>
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                              setResetPassword(false)
                              setRegister(true)
                            }}
                          >
                            Sign up
                          </Link>
                        </>
                    }
                  </>
                )
              }
            </LoginCardContent>
          </LoginCard>
        </Container>
      </div>
    </>
  )
}

