import React, {useEffect} from "react";
import useStyles from "../styles";
import {Button, Container, Grid, Typography} from "@mui/material";
import {CodeCard} from "./Cards";
import {CodeContents} from "./CodeContents";

function Code({projectId, setProjectId}) {

    const classes = useStyles();

    useEffect(() => {
        setProjectId(null)
    },[])

    return(
        <>
            <div className={classes.container}>
                <Container maxWidth={'lg'}>
                    <Typography variant={'h1'} align={'left'} color={'primary'} gutterBottom>
                        Code
                    </Typography>
                    <Typography variant={'subtitle1'} align={'left'} color={'textSecondary'} paragraph>
                        Explore and run machine learning code with Kaggle Notebooks.
                        Find help in the Documentation.
                    </Typography>
                    <div className={classes.buttons}>
                        <Grid container spacing={2} justifyContent={'left'}>
                            <Grid item>
                                <Button variant={'contained'} color={'primary'} disableElevation>
                                    New Notebook
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
            <div className={classes.container}>
                <Container className={classes.cardGrid} maxWidth={'lg'}>
                    <Grid container spacing={4}>
                        {CodeContents.map((code, key) => (
                            <Grid item key={key} xs={12} sm={6} md={4} lg={3}>
                                { CodeCard(code, classes) }
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default Code;
