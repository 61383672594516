export const CodeContents = [
  {
    title: "TPS - AUG 22 - Threshold + Random",
    author: {
      name: "Trixy Tea",
      avatarImage: "",
    },
    updated: "2022-08-08 22:23:34",
    project: "Tabular Playground Series - Aug 2022, TPS AUG - Simple Baseline",
    voteCount: 8,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "[TPS aug 2022]LGBMImputer + Pycaret(0.59065)",
    author: {
      name: "Hote",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "Tabular Playground Series - Aug 2022",
    voteCount: 13,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "XGBoost | Wrangling with Hyperparameters | Guide",
    author: {
      name: "Tommy Shelby",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "Titanic - Machine Learning from Disaster",
    voteCount: 12,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "Shoe🥾Brand👞Classification👟|Nike-Adidas-Converse",
    author: {
      name: "Arya Shah",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "",
    voteCount: 2,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "Good or Bad Credit - Classification",
    author: {
      name: "Shae Queen",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "",
    voteCount: 2,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "Data Insights using EDA | Gender Data",
    author: {
      name: "DEEPNETS",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "",
    voteCount: 2,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "Missing Values",
    author: {
      name: "Kapil Soni",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "Housing Prices Competition for Kaggle Learn Users",
    voteCount: 10,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "SkipGramPreTrain-Multi-task-learning",
    author: {
      name: "Sunil Sun",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "Feedback Prize - Predicting Effective Arguments",
    voteCount: 9,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "👨‍💻 | Loan Default Prediction 💰",
    author: {
      name: "LUÍS FERNANDO TORRES",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "Loan Default Prediction",
    voteCount: 3,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "Sign Language ANN-LR",
    author: {
      name: "OMER TOPCU",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "",
    voteCount: 3,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "Anomaly Detection in Credit Card Transactions",
    author: {
      name: "SUGATA GHOSH",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "Credit Card Fraud Detection",
    voteCount: 16,
    coverImage: "https://source.unsplash.com/random"
  },
  {
    title: "An optimal and simple approach",
    author: {
      name: "BORIS MBOBE",
      avatarImage: "",
    },
    updated: "2022-06-03 22:23:34",
    project: "Titanic - Machine Learning from Disaster",
    voteCount: 4,
    coverImage: "https://source.unsplash.com/random"
  },
]