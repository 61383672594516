import { useState, useEffect } from "react";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import useStyles from "../styles";
import {Typography} from "@mui/material";
import axios from "axios";
import {BackendURI} from "./BackendURI";
import { v4 as uuidv4 } from "uuid";
import {DateTime} from "luxon";

export const getCommentsApi = async () => {
  return [
    {
      id: "1",
      body: "First comment",
      name: "Jack Black",
      avatarImage: "",
      userId: "1",
      parentId: null,
      createdAt: "2021-08-16T23:00:33.010+02:00",
    },
    {
      id: "2",
      body: "Second comment",
      name: "John Trout",
      avatarImage: "",
      userId: "2",
      parentId: null,
      createdAt: "2021-08-16T23:00:33.010+02:00",
    },
    {
      id: "3",
      body: "First comment first child",
      name: "John Trout",
      avatarImage: "",
      userId: "2",
      parentId: "1",
      createdAt: "2021-08-16T23:00:33.010+02:00",
    },
    {
      id: "4",
      body: "Second comment second child",
      name: "John Trout",
      avatarImage: "",
      userId: "2",
      parentId: "2",
      createdAt: "2021-08-16T23:00:33.010+02:00",
    },
  ];
};

export const createCommentApi = async (account, threadId, text, parentId = null) => {
  const comment = {
    id: uuidv4().toString('hex').replace(/-/g,''),
    body: text,
    parentId: parentId,
    author: account,
    created: DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false }),
    threadId: threadId,
  };

  console.log(comment.created)
  axios.post(BackendURI.uri + '/comment', comment, {
    headers: {
      Authorization: localStorage.getItem('idToken')
    }
  }).then((response) => {
    console.log(response)
  }).catch((error) => {
    console.log(error)
  })

  return (
    comment
  )
};

export const updateCommentApi = async (commentId, text) => {
  const updates = {
    id: commentId,
    body: text
  }
  axios.put(BackendURI.uri + '/comment', updates, {
    headers: {
      Authorization: localStorage.getItem('idToken')
    }
  }).then((response) => {
    console.log(response)
  }).catch((error) => {
    console.log(error)
  })
  return { text };
};

export const deleteCommentApi = async (commentId) => {
  axios.delete(BackendURI.uri + '/comment?id=' + commentId, {
    headers: {
      Authorization: localStorage.getItem('idToken')
    }
  }).then((response) => {
    console.log(response)
  }).catch((error) => {
    console.log(error)
  })
  return {};
};

const Comments = ({ account, threadId }) => {
  const classes = useStyles();
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const rootComments = backendComments.filter(
    (backendComment) => backendComment.parentId === null || backendComment.parentId === ""
  );

  const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.created).getTime() - new Date(b.created).getTime()
      );
  const addComment = (account, threadId, text, parentId) => {
    createCommentApi(account, threadId, text, parentId).then((comment) => {
      setBackendComments([comment, ...backendComments]);
      setActiveComment(null);
    });
  };

  const updateComment = (commentId, text) => {
    updateCommentApi(commentId, text).then(() => {
      const updatedBackendComments = backendComments.map((backendComment) => {
        if (backendComment.id === commentId) {
          return { ...backendComment, body: text };
        }
        return backendComment;
      });
      setBackendComments(updatedBackendComments);
      setActiveComment(null);
    });
  };
  const deleteComment = (commentId) => {
    if (window.confirm("Are you sure you want to remove comment?")) {
      deleteCommentApi(commentId).then(() => {
        const updatedBackendComments = backendComments.filter(
          (backendComment) => backendComment.id !== commentId
        );
        setBackendComments(updatedBackendComments);
      });
    }
  };

  useEffect(() => {
    // getCommentsApi().then((data) => {
    axios.get(BackendURI.uri + '/comment?threadId=' + threadId, {
      headers: {
        Authorization: localStorage.getItem('idToken')
      }
    }).then(response =>{
      setBackendComments(response.data);
      console.log(response.data)
    }).catch(error => {
      console.log(error)
    })
  }, [threadId]);

  return (
    <div className={classes.comments}>
      <Typography variant={'h2'} align={'left'} color={'inherit'} >
        Comments
      </Typography>
      <Typography variant={'subtitle1'} align={'left'} color={'inherit'} >
        Post comment
      </Typography>
      <CommentForm submitLabel="Post" handleSubmit={(text) => addComment(account, threadId, text)} />
      <div className={classes.commentsContainer}>
        {
          rootComments.map((rootComment) => (
            <Comment
              account={account}
              threadId={threadId}
              key={rootComment.id}
              comment={rootComment}
              replies={getReplies(rootComment.id)}
              activeComment={activeComment}
              setActiveComment={setActiveComment}
              addComment={addComment}
              deleteComment={deleteComment}
              updateComment={updateComment}
            />
          ))
        }
      </div>
    </div>
  );
};

export default Comments;
