import React from "react";
import Avatar from "@mui/material/Avatar";
import {AvatarGroup} from "@mui/material";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function StringAvatar(name, size) {
  name = name.length ? name : "no name"

  return {
    sx: {
      bgcolor: stringToColor(name),
      width: size,
      height: size,
      fontSize: size/2,
    },
    children: name.match(/ /) ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name[0]}`,
  };
}

export function CustomAvatar(user, size){
  return (
      <>
        {
          ("" + user.avatarImage).length > 1
            ?
            <Avatar
              alt={user.name}
              src={user.avatarImage}
              sx={{height: size, width: size}}
              imgProps={{ referrerPolicy: "no-referrer" }}
            />
            : <Avatar {...StringAvatar("" + user.name, size)}/>
        }
      </>
  )
}

export function CustomAvatarGroup(users, size, max){
  return (
    <AvatarGroup max={max} sx={{'& .MuiAvatar-root': { width: size, height: size },}}>
      {
        users.length > 0 &&
        users.map((user, key) => (
          ("" + user.avatarImage).length > 1
            ?
            <Avatar
              alt={user.name}
              src={user.avatarImage}
              sx={{height: size, width: size}}
              key={key}
              imgProps={{ referrerPolicy: "no-referrer" }}
            />
            : <Avatar {...StringAvatar("" + user.name, size)} key={key}/>
        ))
      }
    </AvatarGroup>
  )
}

export default CustomAvatar;