import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { DateTime } from "luxon";
import { Box, Checkbox, FormControlLabel, InputAdornment } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import TagSelector from "./TagSelector";
import { ImageOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import handleFileUpload from "./FileUploadHandler"
import {BackendURI} from "./BackendURI";

function PopupDialog(
  {
    buttonVariant,
    buttonColor,
    buttonTitle,
    dialogTitleText,
    dialogContentText,
    account,
    type,
    projectContent,
  }) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [privateProject, setPrivateProject] = useState(true);
  const [deadline, setDeadline] = useState(new Date());
  const [selectedTags, setSelectedTags] = useState([]);
  const [storedTags, setStoredTags] = useState([]);
  const [selectedKpi, setSelectedKpi] = useState([]);
  const [storedUsers, setStoredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [storedKpi, setStoredKpi] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [abstract, setAbstract] = useState("");
  const [caption, setCaption] = useState("");
  const navigate = useNavigate()

  const handleToggleVisibility = () => {
    setPrivateProject((p) => !p);
  };

  const handleClickOpen = () => {
    axios.get(BackendURI.uri + '/tag', {
      headers: {
        Authorization: localStorage.getItem('idToken')
      }
    }).then(response => {
      console.log('SUCCESS', response)
      setStoredTags(response.data)
    }).catch((error) => {
      console.log(error)
    })
    type === 'project' && (
      axios.get(BackendURI.uri + '/kpi', {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        console.log('SUCCESS', response)
        setStoredKpi(response.data)
      }).catch((error) => {
        console.log(error)
      })
    )
    type === 'project' && account.organization && (
      axios.get(BackendURI.uri + '/user?organization=' + account.organization[0].id, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        console.log('SUCCESS', response)
        setStoredUsers(response.data)
        setSelectedUsers([account])
      }).catch((error) => {
        console.log(error)
      })
    )
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setTitle("");
    setSubtitle("");
    setDescription("");
    setSelectedTags([]);
    setSelectedKpi([]);
    setSelectedUsers([]);
    setCoverImage(null);
    setAbstract("")
    setCaption("")
  }

  const handleCreateProject = async (account) => {
    const projectId = uuidv4().toString('hex').replace(/-/g,'')
    // await setContentId(projectId)

    const downloadURL = coverImage ? await handleFileUpload(projectId, coverImage) : ''
    console.log('Call create project API: ', await downloadURL)
    const project = {
      id: projectId,
      title: title,
      subtitle: subtitle,
      description: description,
      teamMembers: selectedUsers,
      projectOwner: account,
      created: DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false }),
      deadline: DateTime.fromISO(new Date(deadline).toISOString()).toSQL({ includeOffset: false }),
      visibility: privateProject ? 'private' : 'public',
      tag: selectedTags,
      kpi: selectedKpi,
      coverImage: downloadURL,
    }
    axios.post(BackendURI.uri + '/project', project, {
      headers: {
        Authorization: localStorage.getItem('idToken')
      }
    }).then((response) => {
      console.log(response)
      navigate(projectId)
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleCreateDataset = async (account) => {
    const datasetId = uuidv4().toString('hex').replace(/-/g,'')
    const createAt = DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false })
    const downloadURL = coverImage ? await handleFileUpload(datasetId, coverImage) : ''
    console.log('Call create dataset API: ', await downloadURL)
    const dataset = {
      id: datasetId,
      title: title,
      subtitle: subtitle,
      description: description,
      dataOwner: account,
      created: createAt,
      updated: createAt,
      link: "",
      numFiles: 0,
      dataType: "",
      dataSize: 0,
      license: "Unknown",
      tag: selectedTags,
      coverImage: downloadURL,
      voteCount: 0,
    }
    axios.post(BackendURI.uri + '/data', dataset, {
      headers: {
        Authorization: localStorage.getItem('idToken')
      }
    }).then((response) => {
      console.log(response)
      navigate(datasetId)
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleCreateReport = async (account) => {
    console.log('accountId: ', await account)

    const reportId = uuidv4().toString('hex').replace(/-/g,'')
    const createAt = DateTime.fromISO(new Date().toISOString()).toSQL({ includeOffset: false })
    const downloadURL = coverImage ? await handleFileUpload(reportId, coverImage) : ''
    console.log('Call create report API: ', await downloadURL)
    const report = {
      id: reportId,
      projectId: projectContent.id,
      title: title,
      subtitle: subtitle,
      abstract: abstract,
      author: [account],
      created: createAt,
      updated: createAt,
      keyImage: downloadURL,
      caption: caption,
      description: description,
      project: [projectContent],
      tag: selectedTags,
      voteCount: 0,
    }
    axios.post(BackendURI.uri + '/report', report, {
      headers: {
        Authorization: localStorage.getItem('idToken')
      }
    }).then((response) => {
      console.log(response)
      navigate('../report/' + reportId)
    }).catch((error) => {
      console.log(error)
    })
  }


  const handleCreate = async (account) => {
    type === 'project' && (
      await handleCreateProject(account)
    )
    type === 'dataset' && (
      await handleCreateDataset(account)
    )
    type === 'report' && (
      await handleCreateReport(account)
    )
    setOpen(false);
    setTitle("");
    setSubtitle("");
    setDescription("");
    setSelectedTags([]);
    setSelectedKpi([]);
    setCoverImage(null);
  }

  const handleDeadlineSelect = (newDeadline) => {
    setDeadline(newDeadline);
  };

  const handleFileSelect = (e) => {
    if (e.target.files[0]) {
      setCoverImage(e.target.files[0]);
    }
  }

  console.log('teamMembers: ', selectedUsers)

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant={buttonVariant}
        color={buttonColor}
        disableElevation={true}
      >
        { buttonTitle }
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}>
          <Box>
            <DialogTitle>{ dialogTitleText }</DialogTitle>
          </Box>
          <Box>
            <DialogContent>

              <Box>
                <DialogContentText>
                  { dialogContentText }
                </DialogContentText>
              </Box>

              <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
                {
                  type === "project" && (
                    <Box sx={{ marginLeft: "auto" }}>
                      <FormControlLabel
                        label={'Private Project'}
                        control={
                          <Checkbox
                            checked={privateProject}
                            onChange={handleToggleVisibility}
                          />
                        }
                      />
                    </Box>
                  )
                }
              </Box>

              <Box>
                <TextField
                  margin="dense"
                  id="title"
                  label="Title"
                  type="text"
                  value={title}
                  fullWidth
                  variant="outlined"
                  onChange={e => { setTitle(e.target.value) }}
                />
              </Box>

              <Box>
                <TextField
                  margin="dense"
                  id="subtitle"
                  label="Subtitle"
                  type="text"
                  value={subtitle}
                  fullWidth
                  variant="outlined"
                  onChange={e => { setSubtitle(e.target.value) }}
                />
              </Box>

              {
                type === 'report' && (
                  <>
                    <Box>
                      <TextField
                        margin="dense"
                        id="abstract"
                        label="Abstract"
                        type="text"
                        value={abstract}
                        fullWidth
                        multiline
                        rows={3}
                        variant="outlined"
                        onChange={e => { setAbstract(e.target.value) }}
                      />
                    </Box>
                    <Box>
                      <TextField
                        margin="dense"
                        id="coverImage"
                        label="Key image"
                        placeholder={coverImage ? coverImage.name : "File path to image file"}
                        value={coverImage ? coverImage.name : ""}
                        type="text"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton component={"label"}>
                                <input hidden accept="image/*" type="file" onChange={handleFileSelect}/>
                                <ImageOutlined/>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      >
                      </TextField>
                    </Box>
                    <Box>
                      <TextField
                        margin="dense"
                        id="caption"
                        label="Image caption"
                        type="text"
                        value={caption}
                        fullWidth
                        multiline
                        rows={3}
                        variant="outlined"
                        onChange={e => { setCaption(e.target.value) }}
                      />
                    </Box>
                  </>
                )
              }

              {
                (type === 'project' || type === 'dataset') && (
                  <Box>
                    <TextField
                      margin="dense"
                      id="description"
                      label="Description"
                      placeholder="Description"
                      type="text"
                      value={description}
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      onChange={e => {
                        setDescription(e.target.value)
                      }}
                    />
                  </Box>
                )
              }

              {
                type === 'project' && (
                  <Box>
                    <TagSelector
                      selectedTags={selectedUsers}
                      setSelectedTags={setSelectedUsers}
                      storedTags={storedUsers}
                      label={"Team members"}
                      placeholder={'Team members'}
                      type={'organization'}
                    />
                  </Box>
                )
              }

              {
                type === 'project' && (
                  <Box>
                    <TagSelector
                      selectedTags={selectedTags}
                      setSelectedTags={setSelectedTags}
                      storedTags={storedTags}
                      label={"Project tag"}
                      placeholder={'Tag'}
                    />
                  </Box>
                )
              }

              {
                type === 'dataset' && (
                  <Box>
                    <TagSelector
                      selectedTags={selectedTags}
                      setSelectedTags={setSelectedTags}
                      storedTags={storedTags}
                      label={"Dataset tag"}
                      placeholder={'Tag'}
                    />
                  </Box>
                )
              }

              {
                type === 'report' && (
                  <Box>
                    <TagSelector
                      selectedTags={selectedTags}
                      setSelectedTags={setSelectedTags}
                      storedTags={storedTags}
                      label={"Report tag"}
                      placeholder={'Tag'}
                    />
                  </Box>
                )
              }

              {
                type === 'project' && (
                  <Box>
                    <TagSelector
                      selectedTags={selectedKpi}
                      setSelectedTags={setSelectedKpi}
                      storedTags={storedKpi}
                      label={"KPI"}
                      placeholder={"KPI"}
                    />
                  </Box>
                )
              }

              {
                type === 'project' && (
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <DateTimePicker
                        label={"Deadline Date & Time"}
                        value={deadline}
                        onChange={handleDeadlineSelect}
                        renderInput={(params) => <TextField {...params} margin="dense" />}
                        disableMaskedInput
                      />
                    </LocalizationProvider>
                  </Box>
                )
              }

              {
                ( type === 'project' ||  type === 'dataset' ) && (
                  <Box>
                    <TextField
                      margin="dense"
                      id="coverImage"
                      label="Cover image"
                      placeholder={coverImage ? coverImage.name : "File path to image file"}
                      value={coverImage ? coverImage.name : ""}
                      type="text"
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton component={"label"}>
                              <input hidden accept="image/*" type="file" onChange={handleFileSelect}/>
                              <ImageOutlined/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    >
                    </TextField>
                  </Box>
                )
              }

            </DialogContent>
          </Box>
        </Box>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={() => handleCreate(account)}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PopupDialog;
