// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, browserLocalPersistence, setPersistence } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-PmpLb3-nJZe0XJeOw3W_pubP44VUMIM",
  authDomain: "m-intuition-proto.firebaseapp.com",
  projectId: "m-intuition-proto",
  storageBucket: "m-intuition-proto.appspot.com",
  messagingSenderId: "496485533226",
  appId: "1:496485533226:web:9e481fe694565925096553",
  measurementId: "G-3XWHCTZJSL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
(async () => {
  await setPersistence(auth, browserLocalPersistence);
})();
export const apiKey = firebaseConfig['apiKey']
