import React from "react";
import {Button, Card, CardActions, CardContent, CardMedia, Typography, AvatarGroup} from "@mui/material";
import {styled} from "@mui/material/styles";
// import Divider from "@mui/material/Divider";
import { DateTime } from "luxon";
import Box from "@mui/material/Box";
import CustomAvatar, {StringAvatar} from "./Avatar";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import IconButton from "@mui/material/IconButton";
import {NavLink} from "react-router-dom";
import Avatar from "@mui/material/Avatar";


const StyledCard = styled(Card)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  elevation: "0",
  border: "1px solid lightgrey",
  boxShadow: "none",
}));

const StyledCardMedia = styled(CardMedia)(() => ({
  paddingTop: '56.25%',  // 16:9
}));

const StyledCardContent = styled(CardContent)(() => ({
  flexGrow: 1,
}))

const StyledCardActions = styled(CardActions)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  marginLeft: "10px",
  marginRight: "10px",
}))

export function ProjectCard(project) {
  // const [teamMembers, setTeamMembers] = useState([])

  return (
    <>
      <StyledCard>
        <StyledCardMedia
          image={
            project.coverImage.length > 0
              ? project.coverImage
              : "https://picsum.photos/seed/" + project.id + "/400/225"
          }
          title={'Image title'}
        />
        <StyledCardContent>
          <NavLink
            color={'inherit'}
            to={"/project/" + project.id}
            style={{ textDecoration: 'none' }}
          >
            <Typography gutterBottom variant={'subtitle1'} color={"textPrimary"}>
              { project.title }
            </Typography>
          </NavLink>
          <Typography variant={'body2'} noWrap color={"textSecondary"}>
            { project.subtitle }
          </Typography>
          <Typography variant={'body2'} noWrap color={"textSecondary"}>
            { project.visibility }
          </Typography>
        </StyledCardContent>
        <StyledCardActions disableSpacing>
          <Box>
            <AvatarGroup max={4} sx={{'& .MuiAvatar-root': { width: 32, height: 32 },}}>
              {
                project.teamMembers.length > 0 &&
                project.teamMembers.map((user, key) => (
                  ("" + user.avatarImage).length > 1
                    ? <Avatar alt={"" + user.name} src={user.avatarImage} sx={{height: 32, width: 32}} key={key}/>
                    : <Avatar {...StringAvatar("" + user.name,32)} key={key}/>
                ))
              }
            </AvatarGroup>
          </Box>
          <Box sx={{ marginLeft: 'auto' }}>
            <Typography variant={'subtitle2'} color={"textSecondary"}>
              { DateTime.fromSQL(project.deadline).toRelative() }
            </Typography>
          </Box>
        </StyledCardActions>
      </StyledCard>
    </>
  )
}

export function DataCard(dataset, classes) {
  return (
    <>
      <StyledCard>
        <StyledCardMedia
          image={
            dataset.coverImage.length > 0
              ? dataset.coverImage
              : "https://picsum.photos/seed/" + dataset.id + "/400/225"
          }
          title={'Image title'}
        />
        <StyledCardContent>
          <NavLink
            color={'inherit'}
            to={"/data/" + dataset.id}
            style={{ textDecoration: 'none' }}
          >
            <Typography gutterBottom variant={'subtitle1'} color={"textPrimary"}>
              { dataset.title }
            </Typography>
          </NavLink>
          <Typography variant={'body2'} noWrap color={"textSecondary"}>
            { dataset.subtitle }
          </Typography>
          <Typography variant={'body2'} noWrap color={"textSecondary"}>
            { dataset.dataOwner.name + " · Updated " + DateTime.fromSQL(dataset.updated).toRelative() }
          </Typography>
          <Typography variant={'body2'} noWrap color={"textSecondary"}>
            { dataset.license }
          </Typography>
          <Typography variant={'body2'} noWrap color={"textSecondary"}>
            {
              (dataset.numFiles === 1
                ? dataset.numFiles + " File ("
                : dataset.numFiles + " Files (") +
              dataset.dataType + ")"
            }
          </Typography>
        </StyledCardContent>
        <StyledCardActions disableSpacing>
          <Box>
            <Button
              className={classes.upVoteButton}
              size={'small'}
              variant="outlined"
              startIcon={ <ArrowDropUpIcon /> }
              onClick={() => console.log("up")}
              color={'inherit'}
            >
              {dataset.voteCount}
            </Button>
          </Box>
          <Box sx={{marginLeft: "auto"}}>
            <IconButton
              color="inherit"
              onClick={() => console.log("hi " + dataset.dataOwner.name)}
            >
              { CustomAvatar(dataset.dataOwner, 32) }
            </IconButton>
          </Box>
        </StyledCardActions>
      </StyledCard>
    </>
  )
}

export function CodeCard(code, classes) {
  return (
    <>
      <StyledCard>
        <StyledCardMedia
          image={ code.coverImage }
          title={'Image title'}
        />
        <StyledCardContent>
          <Typography gutterBottom variant={'subtitle1'} color={"textPrimary"}>
            { code.title }
          </Typography>
          <Typography variant={'body2'} noWrap color={"textSecondary"}>
            { "Updated " + DateTime.fromSQL(code.updated).toRelative() }
          </Typography>
          <Typography variant={'body2'} noWrap color={"textSecondary"}>
            { code.project }
          </Typography>
        </StyledCardContent>
        <StyledCardActions disableSpacing>
          <Box>
            <Button
              className={classes.upVoteButton}
              size={'small'}
              // sx={{ borderRadius: 10 }}
              variant="outlined"
              startIcon={ <ArrowDropUpIcon /> }
              onClick={() => console.log("up")}
              color={'inherit'}
            >
              {code.voteCount}
            </Button>
          </Box>
          <Box sx={{marginLeft: "auto"}}>
            <IconButton
              color="inherit"
              onClick={() => console.log("hi " + code.author.name)}
            >
              { CustomAvatar(code.author, 32) }
            </IconButton>
          </Box>
        </StyledCardActions>
      </StyledCard>
    </>
  )
}

export function ReportCard(report, classes) {
  return (
    <>
      <StyledCard>
        <StyledCardMedia
          image={
            report.keyImage.length > 0
              ? report.keyImage
              : "https://picsum.photos/seed/" + report.id + "/400/225"
          }
          title={'Image title'}
        />
        <StyledCardContent>
          <NavLink
            color={'inherit'}
            to={"/report/" + report.id}
            style={{ textDecoration: 'none' }}
          >
            <Typography gutterBottom variant={'subtitle1'} color={"textPrimary"}>
              { report.title }
            </Typography>
          </NavLink>
          <Typography variant={'body2'} noWrap color={"textSecondary"}>
            { report.subtitle }
          </Typography>
          <Typography variant={'body2'} noWrap color={"textSecondary"}>
            { report.author.name + " · Updated " + DateTime.fromSQL(report.updated).toRelative() }
          </Typography>
        </StyledCardContent>
        <StyledCardActions disableSpacing>
          <Box>
            <Button
              className={classes.upVoteButton}
              size={'small'}
              variant="outlined"
              startIcon={ <ArrowDropUpIcon /> }
              onClick={() => console.log("up")}
              color={'inherit'}
            >
              {report.voteCount}
            </Button>
          </Box>
          <Box sx={{marginLeft: "auto"}}>
            <AvatarGroup max={4} sx={{'& .MuiAvatar-root': { width: 32, height: 32 },}}>
              {
                report.author.length > 0 &&
                report.author.map((user, key) => (
                  ("" + user.avatarImage).length > 1
                    ? <Avatar alt={"" + user.name} src={user.avatarImage} sx={{height: 32, width: 32}} key={key}/>
                    : <Avatar {...StringAvatar("" + user.name,32)} key={key}/>
                ))
              }
            </AvatarGroup>
            {/*<IconButton*/}
            {/*  color="inherit"*/}
            {/*  onClick={() => console.log("hi " + report.author.name)}*/}
            {/*>*/}
            {/*  { CustomAvatar(report.author, 32) }*/}
            {/*</IconButton>*/}
          </Box>
        </StyledCardActions>
      </StyledCard>
    </>
  )
}
