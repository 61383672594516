import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex"
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1, 0, 1),
    },
    icon: {
        marginRight: '20px',
    },
    buttons: {
        marginTop: '40px',
    },
    upVoteButton: {
        '&.MuiButton-outlined': {
          border: '1px solid lightgrey',
        },
    },
    cardGrid: {
        padding: '20px 0'
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: '50px 0'
    },
    feedFooter: {
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "8px 0",
        marginRight: "12px",
    },
    feedFooterItem: {
        display: "inline-flex",
        alignItems: "center",
        padding: "0px 6px",
    },
    feedFooterIcon: {
        color: "grey",
        height: "16px",
        width: "16px",
        marginLeft: "6px",
        marginRight: "6px",
    },
    comments: {
        marginTop: "20px",
    },
    commentsContainer: {
        marginTop: "40px",
    },
    commentFormTextarea: {
        width: "100%",
        marginTop: "8px",
        marginBottom: "8px",
        border: "1px solid",
    },
    comment: {
        display: "flex",
        marginBottom: "20px",
    },
    commentImageContainer: {
      marginRight: "12px",
    },
    commentRightPart: {
        width: "100%",
    },
    commentContent: {
        display: "flex",
    },
    commentAuthor: {
        marginRight: "8px",
        fontSize: "20px",
    },
    commentText: {
        fontSize: "18px",
    },
    commentActions: {
        display: "flex",
        fontSize: "12px",
        cursor: "pointer",
        marginTop: "8px",
    },
    commentAction: {
        marginRight: "8px",
    },
    replies: {
        marginTop: "20px",
    },
    imageContainer: {
        marginTop: "24px",
        marginBottom: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto",
    },
    keyImage: {
        maxWidth: "1200px",
        maxHeight: "800px",
    },
    fab: {
        right: "12px",
        bottom: "12px",
        // position: 'fixed',
    },
    popover: {
        "&.MuiPopover-root": {
            borderWidth: "1px",
            borderColor: "black",
        },
        zIndex: 1
      //   border: "1px solid"
    },
    iframe: {
        position: "absolute",
        alignItems: "center",
        marginTop: "0px",
        marginBottom: "6px",
        marginRight: "6px",
        height: "90%",
        width: "99%",
        border: "none",
    }
}))

export default useStyles;
