import React, {useEffect, useState} from "react";
import {Button, Container, Grid, Typography} from "@mui/material";
import {DataCard} from "./Cards";
// import {DatasetContents} from "./DatasetContents";
import {Pagination} from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import PopupDialog from "./PopupDialog";
import {BackendURI} from "./BackendURI";

function Datasets({classes, account, query, projectId, setProjectId}) {
  const [page, setPage] = useState(1);
  const [pageMax, setPageMax] = useState(1);
  const [datasets, setDatasets] = useState([]);

  const handleChange = (event, value) => {
    setPage(value);
    query.length
      ?
      axios.get(BackendURI.uri + '/search?target=data&query=' + query + '&page=' + value, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        response.data.pageMax && setPageMax(response.data.pageMax)
        response.data.datasets && setDatasets(response.data.datasets)
        console.log("SUCCESS", response.data.datasets)
      }).catch(error => {
        console.log(error)
      })
      :
      axios.get(BackendURI.uri + '/data?page=' + value, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        setPageMax(response.data.pageMax)
        setDatasets(response.data.datasets)
        console.log("SUCCESS", response.data.datasets)
      }).catch(error => {
        console.log(error)
      })
  };
  console.log("search query: " + query)

  useEffect(() => {
    setProjectId(null)

    const timer = setTimeout(() => {
      query.length
        ?
        axios.get(BackendURI.uri + '/search?target=data&query=' + query, {
          headers: {
            Authorization: localStorage.getItem('idToken')
          }
        }).then(response => {
          response.data.pageMax && setPageMax(response.data.pageMax)
          response.data.datasets && setDatasets(response.data.datasets)
          setPage(1);
          console.log("SUCCESS", response.data.datasets)
        }).catch(error => {
          console.log(error)
        })
        :
        axios.get(BackendURI.uri + '/data', {
          headers: {
            Authorization: localStorage.getItem('idToken')
          }
        }).then(response => {
          setPageMax(response.data.pageMax)
          setDatasets(response.data.datasets)
          console.log("SUCCESS", response.data.datasets)
        }).catch(error => {
          console.log(error)
        })
    }, query.length ? 800 : 0)

    return () => clearTimeout(timer)
  },[query])
  // console.log(datasets.length)

  return(
    <>
      <div className={classes.container}>
        <Container maxWidth={'lg'}>
          <Typography variant={'h1'} align={'left'} color={'primary'} gutterBottom>
            Datasets
          </Typography>
            <Typography variant={'subtitle1'} align={'left'} color={'textSecondary'} paragraph>
              Explore, analyze, and share quality data.
              Learn more about data types, creating, and collaborating.
            </Typography>
            <div className={classes.buttons}>
              <Grid container spacing={2} justifyContent={'left'}>
                <Grid item>
                  <PopupDialog
                    buttonVariant={"contained"}
                    buttonColor={'primary'}
                    buttonTitle={"New dataset"}
                    dialogTitleText={"Create A New Dataset"}
                    dialogContentText={"test test"}
                    account={account}
                    type={'dataset'}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => {window.location.pathname = '/usr'}}
                  >
                    My Work
                  </Button>
                </Grid>
              </Grid>
            </div>
        </Container>
      </div>
      <div className={classes.container}>
        <Container className={classes.cardGrid} maxWidth={'lg'}>
          <Grid container spacing={2} justifyContent={'right'}>
            <Grid item>
              <Box sx={{m: 2}}>
                <Pagination count={pageMax} page={page} onChange={handleChange} />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            {
              datasets.length > 0 && datasets.map((dataset, key) => (
                <Grid item key={key} xs={12} sm={6} md={4} lg={3}>
                  { DataCard(dataset, classes) }
                </Grid>
              ))
            }
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default Datasets;
