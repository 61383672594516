import React from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

export const SidebarMainContents = [
  {
    title: 'Home',
    icon: <HomeOutlinedIcon />,
    link: '/home'
  },
  {
    title: 'Open projects',
    icon: <AnalyticsOutlinedIcon />,
    link: '/project'
  },
  {
    title: 'Datasets',
    icon: <TableChartOutlinedIcon />,
    link: '/data'
  },
  {
    title: 'Code',
    icon: <CodeOutlinedIcon />,
    link: '/code'
  },
  // {
  //   title: 'Discussions',
  //   icon: <MessageOutlinedIcon />,
  //   link: '/discussions'
  // },
  // {
  //   title: 'Courses',
  //   icon: <SchoolOutlinedIcon />,
  //   link: '/courses'
  // },
]

export const SidebarSubContents = [
  {
    title: 'My Work',
    icon: <BookmarkBorderOutlinedIcon />,
    link: '/usr'
  },
]