import React from "react";
import { Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// import axios from "axios";
// import {BackendURI} from "./BackendURI";
// import {useEffect, useState} from "react";


function Copyright(props) {
  // const [getMessage, setGetMessage] = useState({})
  //
  // useEffect(()=>{
  //   axios.get(BackendURI.uri + '/time', {
  //     headers: {
  //       Authorization: localStorage.getItem('idToken')
  //     }
  //   }).then(response => {
  //     console.log("SUCCESS", response)
  //     setGetMessage(response)
  //   }).catch(error => {
  //     console.log(error)
  //   })
  // }, [])

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.m-intuition.com/">
        Mechanical Intuition,Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {/*{'. -- '}*/}
      {/*{ getMessage.status === 200 ? getMessage.data.time : "LOADING" }*/}
    </Typography>
  );
}

const MuiFooter = styled('footer')(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    padding: '50px 0'
}));

function Footer() {
  return (
    <MuiFooter>
      <Copyright sx={{ pt: 4 }} />
    </MuiFooter>
  )
}

export default Footer;
