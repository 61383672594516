import CommentForm from "./CommentForm";
import IconButton from "@mui/material/IconButton";
import CustomAvatar from "./Avatar";
import React from "react";
import useStyles from "../styles";
import { DateTime } from 'luxon';

const Comment = ({
  account,
  threadId,
  comment,
  replies,
  setActiveComment,
  activeComment,
  updateComment,
  deleteComment,
  addComment,
  parentId = null,
}) => {
  const currentUserId = account.id
  const classes = useStyles();
  const isEditing =
    activeComment &&
    activeComment.id === comment.id &&
    activeComment.type === "editing";
  const isReplying =
    activeComment &&
    activeComment.id === comment.id &&
    activeComment.type === "replying";
  const fiveMinutes = 300000;
  const timePassed = new Date() - new Date(comment.created) > fiveMinutes;
  const canDelete =
    currentUserId === comment.author.id && replies.length === 0 && !timePassed;
  const canReply = Boolean(currentUserId);
  const canEdit = currentUserId === comment.author.id && !timePassed;
  const replyId = parentId ? parentId : comment.id;
  const createdAt = DateTime.fromSQL(comment.created).toRelative();

  return (
    <div key={comment.id} className={classes.comment}>
      <div className={classes.commentImageContainer}>
        <IconButton
          color="inherit"
          onClick={() => console.log("hi " + comment.author.name)}
        >
          { CustomAvatar(comment.author, 48) }
        </IconButton>
      </div>
      <div className={classes.commentRightPart}>
        <div className={classes.commentContent}>
          <div className={classes.commentAuthor}>{comment.author.name}</div>
          <div>{ createdAt }</div>
        </div>
        {!isEditing && <div className={classes.commentText}>{comment.body}</div>}
        {isEditing && (
          <CommentForm
            classes
            submitLabel="Update"
            hasCancelButton
            initialText={comment.body}
            handleSubmit={(text) => updateComment(comment.id, text)}
            handleCancel={() => {
              setActiveComment(null);
            }}
          />
        )}
        <div className={classes.commentActions}>
          {canReply && (
            <div
              className={classes.commentAction}
              onClick={() =>
                setActiveComment({ id: comment.id, type: "replying" })
              }
            >
              Reply
            </div>
          )}
          {canEdit && (
            <div
              className={classes.commentAction}
              onClick={() =>
                setActiveComment({ id: comment.id, type: "editing" })
              }
            >
              Edit
            </div>
          )}
          {canDelete && (
            <div
              className={classes.commentAction}
              onClick={() => deleteComment(comment.id)}
            >
              Delete
            </div>
          )}
        </div>
        {isReplying && (
          <CommentForm
            submitLabel="Reply"
            handleSubmit={(text) => addComment(account, threadId, text, replyId)}
          />
        )}
        {replies.length > 0 && (
          <div className={classes.replies}>
            {replies.map((reply) => (
              <Comment
                account={account}
                threadId={threadId}
                comment={reply}
                key={reply.id}
                setActiveComment={setActiveComment}
                activeComment={activeComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                addComment={addComment}
                parentId={comment.id}
                replies={[]}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;