import './App.css';
import React, {useEffect, useState} from 'react';
import {Routes, Route} from "react-router-dom";
import { CssBaseline, useMediaQuery } from "@mui/material";
import MiniDrawer from "./components/Navigation";
import Feed from "./components/Feed";
import OpenProjects from "./components/OpenProjects";
import Datasets from "./components/Datasets";
import {DatasetDetail, ProjectDetail, ReportDetail} from "./components/ContentDetail";
import Code from "./components/Code";
import UserContents from "./components/UserContents";
import Footer from "./components/Footer";
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import shadows from '@mui/material/styles/shadows';
import useStyles from "./styles";
import Box from "@mui/material/Box";
import axios from "axios";
import { Login } from "./components/Login"
import { Profile } from "./components/Profile";
import {BackendURI} from "./components/BackendURI";
import {auth} from "./.firebase/firebase_config";


const MainBox = styled(Box)(({open}) => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: open ? "240px" : "100px",
  marginRight: "100px"
}))

function Content({account, setAccount, query, projectId, setProjectId}) {
  const classes = useStyles();

  return (
    <>
      <Routes>
        {/*<Route exact path={"/"} element={<Feed classes={classes} account={account} />} />*/}
        <Route path={"/home"}
               element={<Feed
                 classes={classes}
                 account={account}
                 projectId={projectId}
                 setProjectId={setProjectId}
               />}
        />
        <Route path={"/project"}
               element={<OpenProjects
                 classes={classes}
                 account={account}
                 query={query}
                 projectId={projectId}
                 setProjectId={setProjectId}
               />}
        />
        <Route path={"/project/:id"}
               element={<ProjectDetail
                 classes={classes}
                 account={account}
                 projectId={projectId}
                 setProjectId={setProjectId}
               />}
        />
        <Route path={"/data"}
               element={<Datasets
                 classes={classes}
                 account={account}
                 query={query}
                 projectId={projectId}
                 setProjectId={setProjectId}
               />}
        />
        <Route path={"/data/:id"}
               element={<DatasetDetail
                 classes={classes}
                 account={account}
                 projectId={projectId}
                 setProjectId={setProjectId}
               />}
        />
        <Route path={"/report/:id"}
               element={<ReportDetail
                 classes={classes}
                 account={account}
                 projectId={projectId}
                 setProjectId={setProjectId}
               />}
        />
        <Route path={"/code"} element={<Code projectId={projectId} setProjectId={setProjectId}/>} />
        <Route path={"/usr"}
               element={<UserContents
                 classes={classes}
                 account={account}
                 query={query}
                 projectId={projectId}
                 setProjectId={setProjectId}
               />}
        />
        <Route
          path={"/profile"}
          element={<Profile
            classes={classes}
            account={account}
            setAccount={setAccount}
            query={query}
            projectId={projectId}
            setProjectId={setProjectId}
          />}
        />
      </Routes>
    </>
  )
}

function App() {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  // const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const isDarkMode = false;
  const theme = createTheme({
    shadows: shadows.map(() => 'none'),
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
    shape: {
      borderRadius: 20,
    },
    typography: {
      h1: {
        fontSize: 32,
        fontWeight: "bold",
        marginTop: "20px",
      },
      h2: {
        fontSize: 28,
        fontWeight: "bold",
        marginTop: "20px",
      },
      h3: {
        fontSize: 28,
        marginTop: "16px",
      },
      h4: {
        fontSize: 24,
        fontWeight: "bold",
        marginTop: "14px",
      },
      h5: {
        fontSize: 20,
        marginTop: "12px",
      },
      h6: {
        fontSize: 18,
        fontWeight: "bold",
        marginTop: "12px",
      },
      subtitle1: {
        fontSize: 16,
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: "bold",
      },
      body1: {
        fontSize: 16,
        fontWeight: "lighter",
      },
      body2: {
        fontSize: 14,
        fontWeight: "lighter",
      },
      button: {
        fontSize: 14,
      },
      caption: {
        fontSize: 12,
      },
      overline: {
        fontSize: 12,
      },
    },
  });

  const localStorageKey = 'firebase:authUser:' + auth.config.apiKey + ':[DEFAULT]'
  const currentUser = localStorage.hasOwnProperty(localStorageKey)
    ?
    JSON.parse(localStorage.getItem(localStorageKey))
    :
    null

  const [user, setUser] = useState(null);
  // const [authenticated, loading, error] = useAuthState(auth);
  const [userId, setUserId] = useState(currentUser ? currentUser.uid : null);
  const [idToken, setIdToken] = useState(currentUser ? currentUser.stsTokenManager.accessToken : null);
  const [account, setAccount] = useState({});
  const [projectId, setProjectId] = useState(null)

  const getIdToken = async () => {
    // auth.onAuthStateChanged(function(user) {
    //   if (user) {
    //     user.getIdToken().then(function(response) {
    //       setIdToken(response)
    //       sessionStorage.setItem("idToken", response);
    //       console.log('token', response)
    //     }).catch((error) => {
    //       console.log(error)
    //     });
    //   }
    // })
    setIdToken(currentUser.stsTokenManager.accessToken)
    localStorage.setItem("idToken", currentUser.stsTokenManager.accessToken);
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('account')))
    currentUser && getIdToken().then()
    // userId && getIdToken().then()
    // setIdToken(localStorage.getItem('idToken'))

    userId
      ?
      axios.get(BackendURI.uri + '/user/' + userId, {
          headers: {
            Authorization: idToken
          }
        }).then(response => {
        console.log("Account Info", response.data)
        setAccount(response.data)
      })
      :
      currentUser ? setUserId(currentUser.uid) : setUserId(user?.id)
  }, [userId, idToken, localStorageKey])

  console.log('user', user)
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     userId && getIdToken().then();
  //     console.log('token', idToken)
  //   }, 3600000);
  //   return () => clearInterval(interval);
  // }, [])

  // console.log("user", user)
  // console.log("userId", userId)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {
        user
          ?
          <>
            <MiniDrawer
              open={open}
              setOpen={setOpen}
              query={query}
              setQuery={setQuery}
              user={user}
              setUser={setUser}
              setUserId={setUserId}
              projectId={projectId}
            />
            <MainBox component={"main"} open={open}>
              <Content
                account={account}
                setAccount={setAccount}
                query={query}
                projectId={projectId}
                setProjectId={setProjectId}
              />
            </MainBox>
          </>
          :
          <Login setUserId={setUserId} idToken={idToken} setIdToken={setIdToken}/>
      }
      <Footer />
    </ThemeProvider>
  );
}

export default App;
