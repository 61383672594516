import React, {useEffect, useState} from "react";
import {Container, Grid, Typography} from "@mui/material";
import {DataCard, ProjectCard, ReportCard} from "./Cards";
import axios from "axios";
import {BackendURI} from "./BackendURI";


function UserContents({classes, account, query, projectId, setProjectId}) {
  const [projects, setProjects] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [reports, setReports] = useState(null);

  console.log("search query: " + query)

  useEffect(() => {
  setProjectId(null)

    const fetchProjectData = async () => {
      axios.get(BackendURI.uri + '/project?teamMembers=' + account.id, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        setProjects(response.data.projects)
        console.log("Retrieved projects", response.data.projects)
      }).catch(error => {
        console.log(error)
      })
    }

    const fetchDatasetData = async () => {
      axios.get(BackendURI.uri + '/data?dataOwner=' + account.id, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        setDatasets(response.data.datasets)
        console.log("Retrieved datasets", response.data.datasets)
      }).catch(error => {
        console.log(error)
      })
    }

    const fetchReportData = async () => {
      axios.get(BackendURI.uri + '/report?author=' + account.id, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        setReports(response.data.reports)
        console.log("Retrieved reports", response.data.reports)
      }).catch(error => {
        console.log(error)
      })
    }

    const timer = setTimeout(() => {
      query.length && (
        axios.get(BackendURI.uri + '/search?userId=' + account.id + '&query=' + query, {
          headers: {
            Authorization: localStorage.getItem('idToken')
          }
        }).then(response => {
          response.data.projects && setProjects(response.data.projects)
          response.data.datasets && setDatasets(response.data.datasets)
          response.data.reports && setReports(response.data.reports)
          console.log("SUCCESS", response.data)
        }).catch(error => {
          console.log(error)
        })
      )
    }, query.length ? 500 : 0)

    !query.length && account.id && fetchProjectData().then()
    !query.length && account.id && fetchDatasetData().then()
    !query.length && account.id && fetchReportData().then()

    return () => clearTimeout(timer)
  },[account, query])

  return(
    <>
      <div className={classes.container}>
        <Container maxWidth={'lg'}>
          <Typography variant={'h1'} align={'left'} color={'primary'} gutterBottom>
            My Work
          </Typography>
          <Typography variant={'subtitle1'} align={'left'} color={'textSecondary'} paragraph>
            Grow your data science skills by competing in our exciting competitions.
            Find help in the documentation or learn about Community Competitions.
          </Typography>
        </Container>
      </div>

      <div className={classes.container}>
        <Container maxWidth={'lg'}>
          <Typography variant={'h2'} align={'left'} color={'inherit'} gutterBottom>
            Project
          </Typography>
        </Container>

        <Container className={classes.cardGrid} maxWidth={'lg'}>
          <Grid container spacing={4}>
            {
              projects && projects.map((project, key) => (
                <Grid item key={key} xs={12} sm={6} md={4} lg={3}>
                  { project.teamMembers.length > 0 && ProjectCard(project) }
                </Grid>
              ))
            }
          </Grid>
        </Container>

        <Container maxWidth={'lg'}>
          <Typography variant={'h2'} align={'left'} color={'inherit'} gutterBottom>
            Dataset
          </Typography>
        </Container>

        <Container className={classes.cardGrid} maxWidth={'lg'}>
          <Grid container spacing={4}>
            {
              datasets && datasets.map((dataset, key) => (
                <Grid item key={key} xs={12} sm={6} md={4} lg={3}>
                   { DataCard(dataset, classes) }
                </Grid>
              ))
            }
          </Grid>
        </Container>

        <Container maxWidth={'lg'}>
          <Typography variant={'h2'} align={'left'} color={'inherit'} gutterBottom>
            Graphlet
          </Typography>
        </Container>

        <Container className={classes.cardGrid} maxWidth={'lg'}>
          <Grid container spacing={4}>
            {
              reports && reports.map((report, key) => (
                <Grid item key={key} xs={12} sm={6} md={4} lg={3}>
                   { ReportCard(report, classes) }
                </Grid>
              ))
            }
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default UserContents;
