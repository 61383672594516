import React, {useEffect, useState} from "react";
import {AvatarGroup, Button, Chip, Container, Divider, Paper} from "@mui/material"
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import AlarmOnOutlinedIcon from '@mui/icons-material/AlarmOnOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import Typography from '@mui/material/Typography';
import { styled, } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {FeedContents} from "./FeedContents";
import CustomAvatar, {StringAvatar} from "./Avatar";
import {DateTime} from 'luxon';
import useStyles from "../styles";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import {NavLink} from "react-router-dom";
import {BackendURI} from "./BackendURI";


const FeedTimeline = styled(Timeline)(({theme}) =>({
  "& .MuiTimelineItem-missingOppositeContent:before": {
    display: "none"
  },
  // padding: "0 300px"
}));

const FeedPaper = styled(Paper, { variant: "outlined", })(({theme}) =>({
  elevation: "0",
  border: "1px solid lightgrey",
  boxShadow: "0 0",
}));

const FeedIcon = styled(TimelineDot, { variant: "filled" })(({theme}) =>({
  color: "grey",
  backgroundColor: "transparent",
  positionY: "0",
  boxShadow: "0 0",
}));

function getFeedIcon(feedType) {
  switch(feedType) {
    case "project": return <AnalyticsOutlinedIcon />;
    case "dataset": return <TableChartOutlinedIcon />;
    case "code": return <CodeOutlinedIcon />;
    case "comment": return <MessageOutlinedIcon />;
    case "report": return <FeedOutlinedIcon />;
    default: return <AnalyticsOutlinedIcon />;
  }
}

function getUpdatePrefix(feedType) {
  switch(feedType) {
    case "project": return "Created ";
    case "dataset": return "Posted ";
    case "code": return "Checked in ";
    case "comment": return "Posted ";
    case "report": return "Posted ";
    default: return "";
  }
}

function FeedHeader(feed, classes) {
  return (
    <>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        p: 1,
        m: 1,
        alignItems: "center",
      }}>
        <Box>
          <AvatarGroup max={4} sx={{'& .MuiAvatar-root': { width: 48, height: 48 },}}>
            {
              feed.user.length > 0 &&
              feed.user.map((user, key) => (
                ("" + user.avatarImage).length > 1
                  ? <Avatar alt={"" + user.name} src={user.avatarImage} sx={{height: 48, width: 48}} key={key}/>
                  : <Avatar {...StringAvatar("" + user.name,48)} key={key}/>
              ))
            }
          </AvatarGroup>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1}}>
          <Box sx={{ px: 2 }}>
            <Typography variant="subtitle1" component="span">
              {
                feed.user.length > 1
                  ? feed.user[0].name + " et al."
                  : feed.user[0].name
              }
            </Typography>
          </Box>
          <Box sx={{ px: 2 }}>
            <Typography variant="body1" component="span">
              { getUpdatePrefix(feed.type) + DateTime.fromSQL(feed.updated).toRelative() }
            </Typography>
          </Box>
        </Box>

        <Box sx={{ px: 2 }}>
          <Button
            className={classes.upVoteButton}
            variant="outlined"
            startIcon={ <ArrowDropUpIcon /> }
            onClick={() => console.log("up")}
            color="inherit"
          >
            { feed.voteCount }
          </Button>
        </Box>
      </Box>
    </>
  )
}

function getFeedBody(feed, classes) {
  switch(feed.type) {
    case "project": return FeedBodyProject(feed, classes);
    case "dataset": return FeedBodyData(feed, classes);
    case "code": return FeedBodyCode(feed, classes);
    case "comment": return FeedBodyComment(feed, classes);
    case "report": return FeedBodyReport(feed, classes);
    default: return FeedBodyProject(feed, classes);
  }
}

function FeedBodyProject(feed, classes) {
  return (
    <>
      <Box sx={{ p: 2 }}>

        <NavLink
          to={"/project/" + feed.id}
          style={{ textDecoration: 'none' }}
        >
          <Typography variant={"h6"} color={"textPrimary"} >
            { feed.contents.title }
          </Typography>
          <Typography variant={"subtitle1"} color={"textPrimary"} >
            { feed.contents.subtitle }
          </Typography>
        </NavLink>
        <Box className={classes.feedFooter}>
          <Box className={classes.feedFooterItem}>
            <AlarmOnOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}>
              { DateTime.fromSQL(feed.contents.deadline).toRelative() }
            </Typography>
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <FlagOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.kpi } </Typography>
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <LabelOutlinedIcon className={classes.feedFooterIcon}/>
            {
              feed.contents.tag.split(', ').map((tag, key) => (
                <Chip label={tag} key={key} sx={{ ml: 1 }}/>
              ))
            }
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <ForumOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.comment } comments</Typography>
          </Box>

        </Box>
        <Typography variant={'body2'}>
          {feed.contents.description}
        </Typography>
      </Box>
    </>
  )
}

function FeedBodyReport(feed, classes) {
  return (
    <>
      <Box sx={{ p: 2 }}>

        <NavLink
          to={"/report/" + feed.id}
          style={{ textDecoration: 'none' }}
        >
          <Typography variant={"h6"} color={"textPrimary"} >
            { feed.contents.title }
          </Typography>
          <Typography variant={"subtitle1"} color={"textPrimary"} >
            { feed.contents.subtitle }
          </Typography>
        </NavLink>

        <Box className={classes.feedFooter}>
          <Box className={classes.feedFooterItem}>
            <LabelOutlinedIcon className={classes.feedFooterIcon}/>
            {
              feed.contents.tag.split(', ').map((tag, key) => (
                <Chip label={tag} key={key} sx={{ ml: 1 }}/>
              ))
            }
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <ForumOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.comment } comments</Typography>
          </Box>

        </Box>
        <Typography variant={'body2'}>
          {feed.contents.abstract}
        </Typography>
      </Box>
    </>
  )
}

function FeedBodyData(feed, classes) {
  return (
    <>
      <Box sx={{ p: 2 }}>

        <NavLink
          to={"/data/" + feed.id}
          style={{ textDecoration: 'none' }}
        >
          <Typography variant={"h6"} color={"textPrimary"} >
            { feed.contents.title }
          </Typography>
          <Typography variant={"subtitle1"} color={"textPrimary"} >
            { feed.contents.subtitle }
          </Typography>
        </NavLink>

        <Box className={classes.feedFooter}>
          <Box className={classes.feedFooterItem}>
            <InsertDriveFileOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.dataType } </Typography>
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <StorageOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.dataSize } </Typography>
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <InfoOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.license } </Typography>
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <CodeOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.code } related codes</Typography>
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <LabelOutlinedIcon className={classes.feedFooterIcon}/>
            {
              feed.contents.tag.split(', ').map((tag, key) => (
                <Chip label={tag} key={key} sx={{ ml: 1 }}/>
              ))
            }
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <ForumOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.comment } comments</Typography>
          </Box>
        </Box>
        <Typography variant={'body2'}>
          {feed.contents.description}
        </Typography>
      </Box>
    </>
  )
}

function FeedBodyCode(feed, classes) {
  return (
    <>
      <Box sx={{ p: 2 }}>

        <Typography variant={"h6"}>
          { feed.contents.title }
        </Typography>
        <Typography variant={"subtitle1"}>
          { feed.contents.subtitle }
        </Typography>

        <Box className={classes.feedFooter}>
          <Box className={classes.feedFooterItem}>
            <HourglassEmptyOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.execTime } to run</Typography>
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <ArticleOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.lines } lines</Typography>
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <VisibilityOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.view } views</Typography>
          </Box>

          <Divider orientation="vertical" flexItem className={classes.feedFooterItem}/>

          <Box className={classes.feedFooterItem}>
            <InsightsOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.visualizations } visualizations</Typography>
          </Box>
        </Box>
        <Typography variant={'body2'}>
          {feed.contents.body}
        </Typography>
      </Box>
    </>
  )
}

function FeedBodyComment(feed, classes) {
  return (
    <>
      <Box sx={{ p: 2 }}>

        <Typography variant={"h6"}>
          { feed.contents.title }
        </Typography>
        <Typography variant={"subtitle1"}>
          { feed.contents.subtitle }
        </Typography>

        <Box className={classes.feedFooter}>
          <Box className={classes.feedFooterItem}>
            <ForumOutlinedIcon className={classes.feedFooterIcon}/>
            <Typography variant={"body2"}> { feed.contents.replies } replies</Typography>
          </Box>
        </Box>
        <Typography variant={'body2'}>
          {feed.contents.body}
        </Typography>
      </Box>
    </>
  )
}


function Feed({classes, account, projectId, setProjectId}) {
  const [feeds, setFeeds] = useState(null)
  const [pageMax, setPageMax] = useState(1);

  useEffect(() => {
    setProjectId(null)
    account.id && (
      axios.get(BackendURI.uri + '/feed?id=' + account.id, {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        response.data.pageMax && setPageMax(response.data.pageMax)
        response.data.feed && setFeeds(response.data.feed)
        console.log("SUCCESS", response.data)
      }).catch(error => {
        console.log(error)
      })
    )
  }, [account.id])

  console.log(feeds)

  return (
    <div className={classes.container}>
      <Container maxWidth={'lg'}>
        <FeedTimeline>
          <TimelineItem key={"0"}>
            <TimelineSeparator>
              <FeedIcon>
                <DynamicFeedOutlinedIcon />
              </FeedIcon>
              <TimelineConnector sx={{ color: "lightgrey" }} />
            </TimelineSeparator>
            <TimelineContent sx={{px: 2, py: 1}}>
              <Typography variant={"h4"}>
                Recent Updates
              </Typography>
            </TimelineContent>
          </TimelineItem>

          {
            feeds && feeds.map((feed, key) => (
              <TimelineItem key={key}>

                <TimelineSeparator>
                  <FeedIcon>
                    { getFeedIcon(feed.type) }
                  </FeedIcon>
                  <TimelineConnector sx={{ color: "lightgrey" }} />
                </TimelineSeparator>

                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <FeedPaper>
                    { FeedHeader(feed, classes) }
                    <Divider />
                    { getFeedBody(feed, classes) }
                  </FeedPaper>
                </TimelineContent>

              </TimelineItem>
            ))
          }
        </FeedTimeline>
      </Container>
    </div>
  )
}

export default Feed;
