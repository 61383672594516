import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  TextField,
  Typography
} from "@mui/material";
import useStyles from "../styles";
import axios from "axios";
import {DateTime} from "luxon";
import {styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { MuiTelInput } from 'mui-tel-input'
import CustomAvatar from "./Avatar";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TagSelector from "./TagSelector";
import {BackendURI} from "./BackendURI";


const ProfileCard = styled(Card)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  elevation: "0",
  border: "1px solid lightgrey",
  boxShadow: "none",
  marginTop: "24px",
  alignItems: "left",
  justifyContent: "left",
}));

const ProfileCardContent = styled(CardContent)(() => ({
  marginLeft: "16px",
  marginRight: "16px",
  alignItems: "left",
  justifyContent: "left",
  textAlign: "left",
}))

const ProfileCardActions = styled(CardActions)(() => ({
  justifyContent: "left",
  marginLeft: "16px",
  marginRight: "16px",
}))



export function Profile({account, setAccount, projectId, setProjectId}){
  setProjectId(null)
  const classes = useStyles();
  const [userProfile, setUserProfile] = useState({})
  const [updatable, setUpdatable] = useState(false)
  const [storedOrgs, setStoredOrgs] = useState(null)
  const [selectedOrgs, setSelectedOrgs] = useState(null)

  function handleChange(event){
    const { name, value } = event.target;
    setUserProfile((prevValue) => {
      return {
        ...prevValue,
        [name]: value
      };
    });
    account[name] === value ? setUpdatable(false) : setUpdatable(true)
  }

  function handleChangePhone(phoneNumber){
    setUserProfile((prevValue) => {
      return {
        ...prevValue,
        ["tel"]: phoneNumber
      };
    });
    account["tel"] === phoneNumber ? setUpdatable(false) : setUpdatable(true)
  }

  function handleChangeOrg(){
    setUserProfile((prevValue) => {
      return {
        ...prevValue,
        ["organization"]: selectedOrgs
      };
    });
    account["organization"] === selectedOrgs ? setUpdatable(false) : setUpdatable(true)
    // setChangeOrg(false)
  }

  async function handleUpdateProfile(){
    axios.put(BackendURI.uri + '/user', userProfile, {
      headers: {
        Authorization: localStorage.getItem('idToken')
      }
    }).then((response) => {
      console.log(response)
      setAccount(userProfile)
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    !storedOrgs && (
      axios.get(BackendURI.uri + '/user?type=organization', {
        headers: {
          Authorization: localStorage.getItem('idToken')
        }
      }).then(response => {
        console.log('SUCCESS', response)
        setStoredOrgs(response.data)
      }).catch((error) => {
        console.log(error)
      })
    )
    !selectedOrgs && setSelectedOrgs(account?.organization)
    setUserProfile(account)
    setUpdatable(false)
  }, [account]);

  useEffect(() => {
    handleChangeOrg()
  }, [selectedOrgs])

  // console.log('Profile: ', userProfile)
  // console.log('SelectedOrgs', selectedOrgs)

  return (
    <>
      <div className={classes.container}>
        <Container maxWidth={'md'}>
          <ProfileCard>
            <ProfileCardContent>
              <Box sx={{ display: "flex", flexDirection: "row" }}>

                <Box sx={{ display: "flex", flexDirection: "column"}}>
                  <Box sx={{margin: "48px 80px 16px 54px",}}>
                    <IconButton
                      color="inherit"
                      onClick={() => console.log("hi " + account.name)}
                    >
                      { CustomAvatar(userProfile, 96) }
                    </IconButton>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Box sx={{m: 1}}>
                      <CalendarMonthIcon />
                    </Box>
                    <Box>
                    <Typography variant={"body1"}>
                      Joined {DateTime.fromSQL(userProfile.joined).toRelative()}
                    </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                  <Box>
                    <Typography variant={"h6"}>
                      Name:
                    </Typography>
                  </Box>
                  <Box sx={{ m: 1 }}>
                    <TextField
                      onChange={handleChange}
                      name="name"
                      type="text"
                      placeholder="Name"
                      value={userProfile.name || ''}
                      fullWidth
                      margin="dense"
                    />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Box sx={{ m: 1 }}>
                      <BusinessOutlinedIcon />
                    </Box>
                    <Box sx={{ flexGrow: 1, m: 1 }}>
                      <TagSelector
                        selectedTags={selectedOrgs ? selectedOrgs : []}
                        setSelectedTags={setSelectedOrgs}
                        storedTags={storedOrgs ? storedOrgs : []}
                        label={"Organitzation"}
                        placeholder={'Organization name'}
                        type={"organization"}
                      />
                      {/*<TextField*/}
                      {/*  onChange={handleChange}*/}
                      {/*  name="organization"*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Organization name"*/}
                      {/*  value={userProfile.organization || ''}*/}
                      {/*  fullWidth*/}
                      {/*  margin="dense"*/}
                      {/*/>*/}
                    </Box>
                  </Box>

                  {/*<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>*/}
                  {/*  <Box sx={{ m: 1 }}>*/}
                  {/*    <BadgeOutlinedIcon />*/}
                  {/*  </Box>*/}
                  {/*  <Box sx={{flexGrow: 1, m: 1}}>*/}
                  {/*    <TextField*/}
                  {/*      onChange={handleChange}*/}
                  {/*      name="department"*/}
                  {/*      type="text"*/}
                  {/*      placeholder="Team name"*/}
                  {/*      value={userProfile.team || ''}*/}
                  {/*      fullWidth*/}
                  {/*      margin="dense"*/}
                  {/*    />*/}
                  {/*  </Box>*/}
                  {/*  <Box sx={{ m: 1 }}>*/}
                  {/*    <TextField*/}
                  {/*      onChange={handleChange}*/}
                  {/*      name="title"*/}
                  {/*      type="text"*/}
                  {/*      placeholder="title"*/}
                  {/*      value={userProfile.title || ''}*/}
                  {/*      fullWidth*/}
                  {/*      margin="dense"*/}
                  {/*    />*/}
                  {/*  </Box>*/}
                  {/*</Box>*/}

                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Box sx={{ m: 1 }}>
                      <CallOutlinedIcon />
                    </Box>
                    <Box sx={{ flexGrow: 1, m: 1 }}>
                      <MuiTelInput
                        onChange={handleChangePhone}
                        name="tel"
                        type="text"
                        placeholder="Phone number"
                        value={userProfile.tel || ''}
                        fullWidth
                        margin="dense"
                      />
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Box sx={{ m: 1 }}>
                      <EmailOutlinedIcon />
                    </Box>
                    <Box sx={{ flexGrow: 1, m: 1 }}>
                      <TextField
                        onChange={handleChange}
                        name="email"
                        type="email"
                        placeholder="Email"
                        value={userProfile.email || ''}
                        fullWidth
                        margin="dense"
                      />
                    </Box>
                  </Box>

                </Box>
              </Box>
            </ProfileCardContent>
            <ProfileCardActions>
              <Box sx={{ my: 2, marginLeft: "auto"}}>
                <Button
                  className="loginBtn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateProfile}
                  disabled={!updatable}
                >
                  Update
                </Button>
              </Box>
            </ProfileCardActions>
          </ProfileCard>
        </Container>
      </div>
    </>
  )
}

