import useStyles from "../styles";
import React from "react";
import Fab from "@mui/material/Fab";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Box} from "@mui/material";


export function Chat({projectId}){
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openChat = Boolean(anchorEl);
  const id = openChat ? 'simple-popover' : undefined;


  const handleClick = (event) => {
    Boolean(anchorEl) ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    console.log("close reason", reason)
    if (reason && reason === "backdropClick")
      return;
    setAnchorEl(null);
  };

  console.log("projectId@Chat", projectId)

  return(
    <>
      <Fab
        className={classes.fab}
        color="primary"
        aria-label="glChat"
        size="medium"
        onClick={handleClick}
      >
        {/*{ openChat ? <CloseIcon /> : <ForumOutlinedIcon /> }*/}
        <ForumOutlinedIcon />
      </Fab>
      <Popover
        id={id}
        open={openChat}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: -10,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        disableEscapeKeyDown
        disableAutoFocus
        disableEnforceFocus
        disableScrollLock
        className={classes.popover}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "20px",
            width: '300px',
            height: '600px',
            border: "1px solid lightgrey",
          }}
        >
          <Box sx={{display: "flex", flexDirection: "row"}}>
            <Box sx={{flexGrow: 1}}></Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <iframe
              className={classes.iframe}
              src={"https://chat.ds2.m-intuition.com/?pid=" + projectId}
            />
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default Chat;
